import React from 'react'
import { ScreenResultCP } from 'submodules/nerit-framework-ui/common/components/screen/screen-result/ScreenResultCP'
import { FlexCP } from 'submodules/nerit-framework-ui/common/components/flex/FlexCP'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import { NeritFrameworkRoutingHelper } from 'submodules/nerit-framework-ui/common/router/utils/NeritFrameworkRoutingHelper'
import { PublicRouter } from 'app/routers/public/PublicRouter'

/**
 */
export function ScreenContentPresenceConfirmResult(): JSX.Element {

    return (
        <ScreenResultCP
            status={'success'}
            title={'Presença confirmada com sucesso'}
            bottomContent={
                <FlexCP justifyContent={'center'}>
                    <ButtonCP
                        onClick={() => NeritFrameworkRoutingHelper.historyPush(PublicRouter.LOGIN)}
                    >
                        Ver meus agendamentos
                    </ButtonCP>
                </FlexCP>
            }
        />
    )
}
