import {
    registerDecorator,
    ValidationArguments,
    ValidationOptions,
    Validator,
    ValidatorConstraint,
    ValidatorConstraintInterface
} from 'class-validator'
import { ValidationTypes } from '../ValidationTypes'

/**
 * VALIDATOR
 * Valida se é um boolean
 *
 * @author christyansantos
 */
@ValidatorConstraint({ name: ValidationTypes.IS_BOOLEAN })
class IsBooleanConstraint implements ValidatorConstraintInterface {
    validate(value: string, args: ValidationArguments): boolean {
        const validator = new Validator()
        return validator.isBoolean(value)
    }

    defaultMessage(args: ValidationArguments): string {
        return 'Valor deve ser do tipo boleano!'
    }
}

/**
 * DECORATOR
 * @param {ValidationOptions} validationOptions
 * @return {(object: Object, propertyName: string) => void}
 * @constructor
 */
export function IsBoolean(validationOptions?: ValidationOptions) {
    return (object: {}, propertyName: string) => {
        registerDecorator({
            target: object.constructor,
            propertyName,
            options: validationOptions,
            constraints: [],
            validator: IsBooleanConstraint
        })
    }
}
