import { FormModel } from 'submodules/nerit-framework-ui/common/form-state-manager/types/FormModel'
import { ObjectPropsTP } from 'submodules/nerit-framework-utils/utils/types/ObjectPropsTP'
import { IsRequired } from 'submodules/nerit-framework-utils/validators'

export class LoginFormModel extends FormModel {

    @IsRequired()
    phone: string

    @IsRequired()
    token: string

    constructor(initialData?: ObjectPropsTP<LoginFormModel>) {
        super(initialData)
        Object.assign(this, initialData)
    }
}
