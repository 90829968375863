import React from 'react'
import styled from 'styled-components'

type _ScpPropsTP = {
    marginRight?: number,
    marginLeft?: number,
    marginTop?: number,
    marginBottom?: number,
}

interface ILogoCPprops extends _ScpPropsTP {
    height?: number
    width?: number
    imgUrl: string
}

/**
 * COMPONENTE: Logo.
 */
export function LogoCP(props: ILogoCPprops): JSX.Element {

    return (
        <WrapperSCP
            marginTop={props.marginTop ?? 20}
            marginRight={props.marginRight ?? 0}
            marginBottom={props.marginBottom ?? 20}
            marginLeft={props.marginLeft ?? 0}
        >
            <img
                src={props.imgUrl}
                alt={'logo'}
                height={props.height}
                width={props.width}
            />
        </WrapperSCP>
    )
}

const WrapperSCP = styled.div<_ScpPropsTP>`
    margin: ${props => `${props.marginTop!}px ${props.marginRight!}px ${props.marginBottom!}px ${props.marginLeft!}px`};
`
