import * as md5 from 'md5'
import { GeneratedPasswordTP } from 'submodules/nerit-framework-utils/utils/types/GeneratedPasswordTP'

/**
 * Encapsula metodos para criptografar dados.
 */
export class CryptoUtils {

	private constructor () {
		/** Construtor privado impede instanciacao. */
	}

	/** 
	 * Transfoma o passsword desejado para o formato em que ele precisa ser salvo no banco de dados.
	 * Retorna também o salt, já no formato que ele precisa ser salvo no banco de dados.
	 */
	public static generatePassword (md5Password: string): GeneratedPasswordTP {
		let salt = CryptoUtils.getDefaultDecryptedSalt()
		const finalPassword = CryptoUtils.encrypt(md5Password, salt)
		salt = CryptoUtils.reversibleEncrypt(salt)
		return {
			password: finalPassword,
			salt
		}
	}

	/**
	 * Criptografa string passada.
	 */
	public static encrypt (data: string, salt: string): string {
		return md5(data + salt)
	}

	/**
	 * Criptografa o dado passado sem salt
	 */
	public static encryptSaltless (data: string): string {
		return md5(data)
	}

	/**
	 * Valida se o dado entrado condiz com o hash recebido
	 */
	public static compareHash (hash: string, password: string, saltPlainText: string): boolean {
		return CryptoUtils.encrypt(password, saltPlainText) === hash
	}

	/**
	 * Cria um hash reversivel encriptado.
	 */
	public static reversibleEncrypt (text: string): string {
		return Buffer.from(text).toString('base64')
	}

	/**
	 * Descripta um hash reversivel
	 */
	public static decrypt (text: string): string {
		return Buffer.from(text, 'base64').toString('utf8')
	}

	public static toBase64 (text: string): string {
		return Buffer.from(text).toString('base64')
	}

	/** Gera & retorna string com salt de senha padrao NAO criptografado. */
	public static getDefaultDecryptedSalt (fieldSalt?: string): string {
		return `${new Date().getTime()}nerit${fieldSalt}`
	}
}
