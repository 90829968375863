import { Icon, Steps } from 'antd'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { IWizardSteps } from 'submodules/nerit-framework-ui/common/components/wizard/inner/IWizardSteps'
import { LoadingOverlayCP } from 'submodules/nerit-framework-ui/common/components/loading/overlay/LoadingOverlayCP'
import { FlexCP } from 'submodules/nerit-framework-ui/common/components/flex/FlexCP'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'

// eslint-disable-next-line @typescript-eslint/naming-convention
const { Step } = Steps

interface IWizardCPProps {
    steps: IWizardSteps[]
    action?: () => void
    loading?: boolean
    showBackButton?: boolean
    currentStep?: number
    hideButtons?: boolean
    onChangeStep?: (currentStep: number) => void
    headerClickable?: boolean
}

/**
 * Componente de Passo a passo
 */
export function WizardCP(props: IWizardCPProps): JSX.Element {

    const [currentStepIndex, setCurrentStepIndex] = useState<number>(props.currentStep ?? 0)
    useEffect(() => setCurrentStepIndex(props.currentStep ?? 0), [props.currentStep])

    /**
     * Avanca.
     */
    async function next(customAction?: () => Promise<boolean> | boolean, nextStep?: number): Promise<void> {

        let canGoNext = true
        if (customAction)
            canGoNext = await customAction()

        if (!canGoNext)
            return

        const newStep = nextStep ?? (currentStepIndex + 1)

        setCurrentStepIndex(newStep)
        if (!!props.onChangeStep)
            props.onChangeStep(newStep)
    }

    /**
     * Retorna um passo
     */
    function prev(prevStep?: number): void {
        const newStep = prevStep ?? (currentStepIndex - 1)

        setCurrentStepIndex(newStep)
        if (!!props.onChangeStep)
            props.onChangeStep(newStep)
    }

    const currentStep = props.steps[currentStepIndex]
    return (
        <WizardContainerSCP>

            <LoadingOverlayCP show={props.loading || false}/>

            <Steps
                current={currentStepIndex}
                onChange={!props.headerClickable
                    ? undefined
                    : (stepSelected) => {
                        if (stepSelected > currentStepIndex)
                            next(props.steps[currentStepIndex].nextAction, stepSelected)
                        else
                            prev(stepSelected)
                    }
                }
            >
                {
                    props.steps.filter((item) => !item.hide)?.map((item) => (
                        <Step
                            key={item.key}
                            title={item.title}
                            icon={item.icon && <Icon type={item.icon}/>}
                        />
                    ))
                }
            </Steps>

            <ContentSCP>
                {currentStep.content}
            </ContentSCP>

            {
                !props.hideButtons &&
                <FlexCP justifyContent={'flex-end'} margin={{top: 10}}>
                    {
                        (props.showBackButton || props.showBackButton === undefined) && currentStepIndex > 0 &&
                        <ButtonCP
                            marginRight={8}
                            onClick={() => prev()}
                        >
                            Voltar
                        </ButtonCP>
                    }

                    {
                        (currentStepIndex < props.steps.length - 1) && !currentStep.hideNexButton &&
                        <ButtonCP
                            type={'primary'}
                            disabled={!!currentStep.canContinue && !currentStep.canContinue()}
                            onClick={() => { next(currentStep.nextAction) }}
                        >
                            Próximo
                        </ButtonCP>
                    }

                    {
                        currentStepIndex === props.steps.length - 1 && !!props.action &&
                        <ButtonCP
                            type={'primary'}
                            disabled={!!currentStep.canContinue && !currentStep.canContinue()}
                            onClick={() => {
                                props.action!()
                            }}
                        >
                            Confirmar
                        </ButtonCP>
                    }
                </FlexCP>
            }

        </WizardContainerSCP>
    )

}

const WizardContainerSCP = styled.div`
    margin: 10px 0
`

const ContentSCP = styled.div`
    margin-top: 16px;
    border: 1px dashed #e9e9e9;
    border-radius: 6px;
    padding: 10px;
`
