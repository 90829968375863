import React, { useEffect, useState } from 'react'
import { ScreenResultCP } from 'submodules/nerit-framework-ui/common/components/screen/screen-result/ScreenResultCP'
import { LayoutPortalPagesCP } from 'app/components/layout-portal-pages/LayoutPortalPagesCP'
import styled from 'styled-components'
import { useParams } from 'react-router'
import { LoadingOverlayCP } from 'submodules/nerit-framework-ui/common/components/loading/overlay/LoadingOverlayCP'
import { CustomerPortalUserAppointmentResponseDTO } from 'submodules/neritclin-sdk/services/customer-portal/customer-portal/dtos/response/CustomerPortalUserAppointmentResponseDTO'
import { CustomerPortalUtils } from 'submodules/neritclin-sdk/services/customer-portal/customer-portal/utils/CustomerPortalUtils'
import { ScreenContentConfirmPresence } from 'modules/scheduler/screens/screen-presence-confirmation/content/ScreenContentConfirmPresence'
import { ScreenContentPresenceConfirmResult } from 'modules/scheduler/screens/screen-presence-confirmation/content/ScreenContentPresenceConfirmResult'

/**
 */
export function ScreenPresenceConfirmation(): JSX.Element {

    const routeParams = useParams<{token: string}>()
    const [view, setView] = useState<'invalidToken' | 'confirmPresence' | 'result'>()
    const [userAppointment, setUserAppointment] = useState<CustomerPortalUserAppointmentResponseDTO>()

    useEffect(init, [])

    /**
     */
    function init(): void {

        const decryptedToken = CustomerPortalUtils.decryptPresenceConfirmationToken(routeParams.token)
        if (!decryptedToken)
            return setView('invalidToken')

        setView('confirmPresence')
        setUserAppointment(decryptedToken.userAppointment)
    }

    if (view === undefined)
        return <LoadingOverlayCP show={true}/>

    if (view === 'invalidToken' || !userAppointment)
        return <ScreenResultCP status={'error'} title={'Dados inválidos'}/>

    return (
        <LayoutPortalPagesCP
            header={{ title: 'Confirmação de Presença' }}
            content={
                <WrapperSCP>
                    {
                        view === 'confirmPresence' &&
                        <ScreenContentConfirmPresence
                            token={routeParams.token}
                            userAppointment={userAppointment}
                            onConfirm={() => setView('result')}
                        />
                    }
                    {
                        view === 'result' &&
                        <ScreenContentPresenceConfirmResult/>
                    }
                </WrapperSCP>
            }
        />
    )
}

const WrapperSCP = styled.div`
  .ant-result {
    padding: 0 !important;
  }
`
