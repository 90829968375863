import { ISchemaResponseDTO } from 'app/services/dtos/response/ISchemaResponseDTO'
import { RequestHelper } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestHelper'
import { IApiReturn } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/types/IApiReturn'
import { AxiosResponse } from 'axios'
import { SysAdminRequests } from 'app/services/SysAdminRequests'
import { AppConfig } from 'config/AppConfig'

/**
 */
export class RoutingHelper {

    /**
     */
    public static async validateDomain(): Promise<ISchemaResponseDTO | undefined> {

        try {
            const domain = window.location.pathname.split('/')[1]

            const reqConfig = SysAdminRequests.getSchemaDataConfig(domain, AppConfig.getInstance().anonymousUserToken)
            const validationReturn = await RequestHelper.runRequest(reqConfig)

            const schemaData = ((validationReturn as AxiosResponse)?.data as IApiReturn<ISchemaResponseDTO>)?.data

            if (!schemaData)
                return

            // AuthActions.setDomain(schemaData)
            return schemaData

        } catch (err) {
            return
        }
    }

}
