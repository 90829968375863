import * as styledComponents from 'styled-components'
import { ThemedStyledComponentsModule } from 'styled-components'
import { IThemeAnt } from 'submodules/nerit-framework-ui/theme/ant/IThemeAnt'

/**
 * Wrapper do styled components para que o theme esteja tipado
 */
const {
    default: styled,
    css,
    keyframes,
    ThemeProvider,
    withTheme,
    ThemeContext
} = (styledComponents as any as ThemedStyledComponentsModule<IThemeAnt>)

export { css, keyframes, ThemeProvider, styled, withTheme, ThemeContext }
export default styled
