import { HttpMethodEnum } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/enums/HttpMethodEnum'
import { RequestConfigTP } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/types/RequestConfigTP'
import { NeritClinSystemApiEnum } from 'submodules/neritclin-sdk/common/NeritClinSystemApiEnum'
import { CustomerLoginRequestDTO } from 'submodules/neritclin-sdk/services/customer-portal/auth/dtos/requests/CustomerLoginRequestDTO'
import { SendValidationTokenRequestDTO } from 'submodules/neritclin-sdk/services/customer-portal/auth/dtos/requests/SendValidationTokenRequestDTO'

export class CustomerAuthRequests {

	public static CONTROLLER_ROOT = 'customer-auth'
	public static SEND_VALIDATION_TOKEN_EP = 'send-validation-token'
	public static LOGIN = 'login'

	static sendToken = (dto: SendValidationTokenRequestDTO): RequestConfigTP<void> => ({
		baseUrlType: NeritClinSystemApiEnum.NERITCLIN_API,
		url: `${CustomerAuthRequests.CONTROLLER_ROOT}/${CustomerAuthRequests.SEND_VALIDATION_TOKEN_EP}`,
		method: HttpMethodEnum.POST,
		params: dto
	})

	static login = (dto: CustomerLoginRequestDTO): RequestConfigTP<void> => ({
		baseUrlType: NeritClinSystemApiEnum.NERITCLIN_API,
		url: `${CustomerAuthRequests.CONTROLLER_ROOT}/${CustomerAuthRequests.LOGIN}`,
		method: HttpMethodEnum.POST,
		params: dto,
	})

}
