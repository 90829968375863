import { registerDecorator, ValidationArguments, ValidationOptions, Validator, ValidatorConstraint, ValidatorConstraintInterface } from 'class-validator'
import { ValidationTypes } from 'submodules/nerit-framework-utils/validators/ValidationTypes'

@ValidatorConstraint({ name: ValidationTypes.IS_REQUIRED })
class IsRequiredConstraint implements ValidatorConstraintInterface {

    validate(value: string, args: ValidationArguments): boolean {

        const validator = new Validator()
        let success = validator.isDefined(value)

        // Valida se eh string
        if (validator.isString(value))
            success = success && value.trim() !== ''

        return success
    }

    defaultMessage(args: ValidationArguments): string {
        return 'Campo obrigatório!'
    }
}

export function IsRequired(validationOptions?: ValidationOptions) {

    return (object: {}, propertyName: string) => {
        registerDecorator({
            target: object.constructor,
            propertyName,
            options: validationOptions,
            constraints: [],
            validator: IsRequiredConstraint
        })
    }
}
