import React, { useEffect } from 'react'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'
import { CustomerPortalRequests } from 'submodules/neritclin-sdk/services/customer-portal/customer-portal/CustomerPortalRequests'
import { NotificationHelper } from 'submodules/nerit-framework-ui/common/components/notification/inner/NotificationHelper'
import { SessionCodesRequestDTO } from 'submodules/neritclin-sdk/services/sessions/dtos/requests/SessionCodesRequestDTO'
import { ButtonSizeTP } from 'submodules/nerit-framework-ui/common/components/button/inner/ButtonSizeTP'
import { ConditionalRenderCP } from 'submodules/nerit-framework-ui/common/components/conditional-render/ConditionalRenderCP'
import { AppStateUtils } from 'app/redux/AppStateUtils'

interface ICPProps {
    sessionCodes: number[]
    mode: 'cancel' | 'reschedule'
    onCancel: () => void
    appearance?: {
        buttonSize?: ButtonSizeTP
    }
}

/**
 */
export function ButtonCancelAppointmentCP(props: ICPProps): JSX.Element {

    const cancelAppointmentRequest = useRequest<void>('none')
    const renderizar = AppStateUtils.getDomainData()?.slug == 'lisolaser';
    useEffect(onCancelAppointmentRequestChange, [cancelAppointmentRequest.isAwaiting])

    /**
     */
    function cancel(): void {

        const dto: SessionCodesRequestDTO = {
            codes: props.sessionCodes
        }
        cancelAppointmentRequest.runRequest(CustomerPortalRequests.cancelUserAppointment(dto))
    }

    /**
     */
    function onCancelAppointmentRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(cancelAppointmentRequest, NotificationHelper.DEFAULT_ERROR_DELETE_MESSAGE, NotificationHelper.DEFAULT_SUCCESS_DELETE_MESSAGE, true))
            return
        
        props.onCancel()
        // if (cancelMode === 'cancel')
        //
        // else
        //     NeritFrameworkRoutingHelper.historyPush(PrivateUserRouter.SCHEDULER)
    }

    return (
        <ConditionalRenderCP shouldRender={renderizar}>
            <ButtonCP
                size={props.appearance?.buttonSize}
                confirmMsg={props.mode === 'cancel'
                    ?'Você tem certeza que deseja cancelar esse agendamento?'
                    : 'Você tem certeza que deseja cancelar esse agendamento e reagendar?'
                }
                onClick={cancel}
                loading={cancelAppointmentRequest.isAwaiting}
            >
                {props.mode === 'cancel' ? 'Cancelar' : 'Cancelar e Reagendar'}
            </ButtonCP>
        </ConditionalRenderCP>
    )
}
