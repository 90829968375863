import React, { useEffect } from 'react'
import { FormStateManagerUtils } from 'submodules/nerit-framework-ui/common/form-state-manager/FormStateManagerUtils'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import { InputCP } from 'submodules/nerit-framework-ui/common/components/form-fields/input/InputCP'
import { InputMaskTypeEnum } from 'submodules/nerit-framework-ui/common/form-state-manager/enums/InputMaskTypeEnum'
import { IconICP } from 'submodules/nerit-framework-ui/common/components/icon/inner/IconICP'
import { LoginFormModel } from 'app/components/form-login/form-model/LoginFormModel'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'
import { CustomerAuthRequests } from 'submodules/neritclin-sdk/services/customer-portal/auth/CustomerAuthRequests'
import { CustomerLoginRequestDTO } from 'submodules/neritclin-sdk/services/customer-portal/auth/dtos/requests/CustomerLoginRequestDTO'
import md5 from 'md5'
import { AuthActions } from 'app/utils/AuthActions'
import { NeritFrameworkRoutingHelper } from 'submodules/nerit-framework-ui/common/router/utils/NeritFrameworkRoutingHelper'
import { PrivateUserRouter } from 'app/routers/private/PrivateUserRouter'
import { IFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/types/IFormStateManager'
import { FlexCP } from 'submodules/nerit-framework-ui/common/components/flex/FlexCP'
import { CustomerAuthResponseDTO } from 'submodules/neritclin-sdk/services/customer-portal/auth/dtos/responses/CustomerAuthResponseDTO'

interface ICPProps {
    formStateManager: IFormStateManager<LoginFormModel>
    onRequestToken: () => void
}

/**
 */
export function FormLoginDataICP(props: ICPProps): JSX.Element {

    const loginRequest = useRequest<CustomerAuthResponseDTO>()
    useEffect(onLoginRequestChange, [loginRequest.isAwaiting])

    /**
     */
    async function doLogin(): Promise<void> {

        if (!await FormStateManagerUtils.validateRequiredFields(props.formStateManager))
            return

        const formValues = props.formStateManager.getFormValues()!
        const dto: CustomerLoginRequestDTO = {
            phone: formValues.phone,
            token: md5(formValues.token),
        }
        loginRequest.runRequest(CustomerAuthRequests.login(dto))
    }

    /**
     */
    function onLoginRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(loginRequest, 'Usuário ou Senha inválidos'))
            return

        const auth = loginRequest.responseData!       
        let entrar = true;
        if(auth.customer.franchises.length){
            
        }
        auth.customer.franchises.forEach(item =>{
            if(item.code == 102){
                entrar = false;
                alert("Favor entrar em contato com a clinica.");
            }
        })    
        if (entrar) {
            AuthActions.setLoggedUser(auth.customer, auth.token)
            NeritFrameworkRoutingHelper.historyPush(PrivateUserRouter.HOME)
        }
    }

    return (
        <>
            <InputCP
                label={'Telefone'}
                fieldName={'phone'}
                formStateManager={props.formStateManager}
                mask={InputMaskTypeEnum.PHONE}
                required={true}
                icon={<IconICP iconName={'phone'} />}
            />

            <InputCP
                label={'Código de Verificação'}
                fieldName={'token'}
                type={'number'}
                maxlength={4}
                formStateManager={props.formStateManager}
                required={true}
                icon={<IconICP iconName={'lock'} />}
            />

            <FlexCP
                justifyContent={'space-between'}
                margin={{ top: 20 }}
            >
                <ButtonCP
                    onClick={props.onRequestToken}
                >
                    Não tenho Código
                </ButtonCP>

                <ButtonCP
                    type={'primary'}
                    onClick={doLogin}
                    loading={loginRequest.isAwaiting}
                >
                    Entrar
                </ButtonCP>
            </FlexCP>
        </>
    )
}
