import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { NeritFrameworkRoutingHelper } from 'submodules/nerit-framework-ui/common/router/utils/NeritFrameworkRoutingHelper'
import { PublicRouter } from 'app/routers/public/PublicRouter'
import { PrivateAccessVerifierCP } from 'app/components/private-access-verifier/PrivateAccessVerifierCP'
import { RouterSwitchCP } from 'submodules/nerit-framework-ui/common/router/components/router-switch/RouterSwitchCP'
import { LoadingOverlayCP } from 'submodules/nerit-framework-ui/common/components/loading/overlay/LoadingOverlayCP'
import { RoutingHelper } from 'config/RoutingHelper'
import { AuthActions } from 'app/utils/AuthActions'
import { ScreenResultCP } from 'submodules/nerit-framework-ui/common/components/screen/screen-result/ScreenResultCP'
import { AppStateUtils } from 'app/redux/AppStateUtils'

/**
 * Router principal da aplicacao
 *
 * 1. Instancia o Routing HELPER, para ser usado na aplicacao
 * 2. Valida se a ROTA possui os dominios WL
 * 3. Renderiza as ROTAS
 *  3.1 Publica, caso seja rota publica
 *  3.2 Caso nao seja rota publica, entrar no AccessVerifier para validar se esta logao
 */
export function AppRouter(): JSX.Element {

    const routeHistory = useHistory()
    useEffect(() => NeritFrameworkRoutingHelper.init(routeHistory), [routeHistory])

    const [isValidDomain, setIsValidDomain] = useState<boolean>()
    useEffect(() => { validateDomain() }, [])

    /**
     */
    async function validateDomain(): Promise<void> {

        const domain = await RoutingHelper.validateDomain()
        if (!domain) {
            setIsValidDomain(false)
            return
        }

        AuthActions.setDomain(domain)

        // Aguarda ate a sessao estar com o dominio
        let count = 0
        let intervalID = setInterval(() => {

            if (count > 5) {
                setIsValidDomain(false)
                clearInterval(intervalID)
            }

            if (!!AppStateUtils.getDomainData()) {
                setIsValidDomain(true)
                clearInterval(intervalID)
            }

            count ++
        }, 100)
    }

    if (isValidDomain === undefined)
        return <LoadingOverlayCP show={true}/>

    if (!isValidDomain)
        return <ScreenResultCP status={'404'} title={'Sistema não encontrado'}/>

    if (!AppStateUtils.getDomainData())
        return <ScreenResultCP status={'warning'} title={'Aguardando configurações'}/>

    return (
        <RouterSwitchCP
            routes={[
                { path: PublicRouter.ROOT, component: PublicRouter, },
                { path: '', component: PrivateAccessVerifierCP }
            ]}
        />
    )
}
