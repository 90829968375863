import React from 'react'
import CheckboxGroup, { CheckboxOptionType } from 'antd/lib/checkbox/Group'
import { styled } from 'submodules/nerit-framework-ui/theme/_old/styledWithTheme'

export interface ICPProps {
    options: CheckboxOptionType[]
    value?: any
    onChange: (value: any) => void
    disabled?: boolean
    name?: string
    mode?: 'horizontal' | 'vertical'
}

/**
 */
export function CheckboxGroupCP(props: ICPProps): JSX.Element {

    return (
        <WrapperSCP mode={props.mode ?? 'horizontal'}>

            <CheckboxGroup
                options={props.options}
                value={props.value}
                onChange={props.onChange}
                disabled={props.disabled}
                name={props.name}
            />

        </WrapperSCP>
    )
}

const WrapperSCP = styled.div<{ mode: 'horizontal' | 'vertical' }>`

  span {
    font-size: 13px;
    color: #898989;
  }

  input {
    margin-right: 3px;
  }

  .ant-checkbox-wrapper {
    margin-right: 10px;
    display: ${props => props.mode === 'vertical' ? 'block' : undefined};
  }

`
