import { EnvironmentEnum } from 'submodules/nerit-framework-utils/utils/enums/EnvironmentEnum'

/**
 * Definicao de parametros de configuracao globais do app.
 */
export class AppConfig {

    readonly environment = process.env.REACT_APP_STAGE as EnvironmentEnum
    readonly reduxEncryptKey = 'bmVyaXRzZvvyZX1rZXk='

    readonly defaultTimeZone = 'America/Sao_Paulo'

    readonly uiBaseUrl: string
    readonly apiBaseUrl: string

    readonly anonymousUserToken: string

    readonly space4LeadsApiBaseUrl: string
    readonly space4LeadsBeeRadsSurveyToken: string
    readonly space4LeadsBeeRadsProjectCode: number

    /** Instancia unica da classe. */
    private static instance: AppConfig

    private constructor() {

        switch (this.environment) {
            case EnvironmentEnum.DEV:
                this.uiBaseUrl = 'http://localhost:3000'
                this.apiBaseUrl = 'http://localhost:3001'
                this.anonymousUserToken = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyQ29kZSI6MSwiaWF0IjoxNjA2OTE3NTkyfQ.vVV6TbAGvXeH4Mo-IG0fIJ3myLmKOk023HF95kisKKc'
                this.space4LeadsApiBaseUrl = 'http://localhost:3005'
                this.space4LeadsBeeRadsSurveyToken = 'MTQx'
                this.space4LeadsBeeRadsProjectCode = 106
                break

            case EnvironmentEnum.HOM:
                this.uiBaseUrl = 'https://hom.portal.neritclin.com.br'
                this.apiBaseUrl = 'https://hom.api.neritclin.com.br'
                this.anonymousUserToken = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyQ29kZSI6MSwiaWF0IjoxNjA2OTE3NTkyfQ.iLFIyoyHh0BP17h53cip13fwkfH7j4tv1iNKAJBUd34'
                this.space4LeadsApiBaseUrl = 'https://api.space4leads.com'
                this.space4LeadsBeeRadsSurveyToken = 'MTQx'
                this.space4LeadsBeeRadsProjectCode = 106
                break

            case EnvironmentEnum.PRD:
                this.uiBaseUrl = 'https://portal.lumiacrm.com.br'
                this.apiBaseUrl = 'https://app.lumiacrm.com.br/api'
                this.anonymousUserToken = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyQ29kZSI6MSwiaWF0IjoxNjA2OTE3NTkyfQ.iLFIyoyHh0BP17h53cip13fwkfH7j4tv1iNKAJBUd34'
                break

            case EnvironmentEnum.TEST:
            default:
                throw new Error('Ambiente não configurado!')
        }
    }

    /** Gera & retorna instancia unica da classe. */
    static getInstance(): AppConfig {
        if (!this.instance)
            this.instance = new AppConfig()
        return this.instance
    }
}
