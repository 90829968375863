import { ValidationError } from 'class-validator'
import * as _ from 'lodash'
import { IFormModelValidator } from 'submodules/nerit-framework-ui/common/form-state-manager/types/IFormModelValidator'
import { IFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/types/IFormStateManager'
import { DictionaryTP } from 'submodules/nerit-framework-ui/common/form-state-manager/types/DictionaryTP'
import { FormValuesMapTP } from 'submodules/nerit-framework-ui/common/form-state-manager/types/FormStateTypes'

/**
 * FORM STATE
 * Controlador de estado de formulario: Gerencia validacao de dados, estado de alteracao dos campos, etc.
 */
export class FormStateManager<FModelValidatorTP extends IFormModelValidator> implements IFormStateManager<FModelValidatorTP> { // eslint-disable-line @typescript-eslint/naming-convention

    /**
     * @inheritDoc
     */
    isValid = true

    /**
     * @inheritDoc
     */
    isDirty = false

    /**
     * @inheritDoc
     */
    validationsCount = 0

    /**
     * @inheritDoc
     */
    considerAllErrors = false

    private _errors: DictionaryTP<FModelValidatorTP, ValidationError>

    private readonly _formName?: string
    private readonly _initialValues: FModelValidatorTP
    private _formFields: FModelValidatorTP
    private _dirtyFields: Array<keyof FModelValidatorTP> = []

    constructor(initialValues: FModelValidatorTP, formName?: string) {
        this._formFields = _.cloneDeep(initialValues)
        this._initialValues = _.cloneDeep(initialValues)
        this._formName = formName
    }

    /**
     * @inheritDoc
     */
    changeFieldValue(fieldName: keyof FModelValidatorTP, value: any): void {
        this.isDirty = true
        this._formFields[fieldName] = value
        this.setFieldDirty(fieldName)
    }

    /**
     * @inheritDoc
     */
    async validate(): Promise<boolean> {

        this._errors = {}
        let errors = await this._formFields.validate()

        if (!!this._formFields.customValidation) {
            errors = [
                ...errors,
                ...await this._formFields.customValidation()
            ]
        }

        this.validationsCount++
        this.isValid = !errors.length
        if (this.isValid)
            return true

        errors.forEach(error => this._errors[error.property] = error)
        return false
    }

    /**
     * @inheritDoc
     */
    getFieldError(fieldName: keyof FModelValidatorTP): ValidationError | undefined {
        return (this.considerAllErrors || this._isFieldDirty(fieldName))
            ? this._errors[fieldName]
            : undefined
    }

    /**
     * @inheritDoc
     */
    getFormValues(): FormValuesMapTP<FModelValidatorTP> {

        const values = {}

        Object.keys(this._formFields)
            .forEach((fieldName: string) => {
                const value = this._formFields[fieldName]
                if (typeof value !== 'function')
                    values[fieldName] = value
            })
        return values as FormValuesMapTP<FModelValidatorTP>
    }

    /**
     * @inheritDoc
     */
    getFieldValue(fieldName: keyof FModelValidatorTP): any {
        return this._formFields[fieldName]
    }

    /**
     * @inheritDoc
     */
    async reset(replacingData?: FModelValidatorTP): Promise<void> {

        this.isDirty = false
        this.considerAllErrors = false
        this._errors = {}
        this._dirtyFields = []
        this.validationsCount = 0
        this._formFields = _.cloneDeep(this._initialValues)

        if (!!replacingData) {
            Object.keys(this._formFields)
                .forEach(fieldName => {
                    this._formFields[fieldName] = replacingData[fieldName]
                })
        }

        await this.validate()
    }

    /**
     * @inheritDoc
     */
    setFieldDirty(fieldName: keyof FModelValidatorTP): void {
        if (!this._isFieldDirty(fieldName))
            this._dirtyFields.push(fieldName)
    }

    /**
     * @inheritDoc
     */
    setConsiderAllErrors(consider: boolean): void {
        this.considerAllErrors = consider
    }

    /**
     * @inheritDoc
     */
    debugFieldValues(): void {
        const formNameDebug = !!this._formName ? `(${this._formName})` : ''
        console.log(`DEBUG | FormStateManager: ${formNameDebug} | Campos:`, this._formFields)   // eslint-disable-line no-console
    }

    /**
     * @inheritDoc
     */
    debugErrors(): void {
        const formNameDebug = !!this._formName ? `(${this._formName})` : ''
        console.log(`DEBUG | FormStateManager: ${formNameDebug} | Erros:`, this._errors)    // eslint-disable-line no-console
    }

    /** Avalia & retorna SE 01 campo do formulario esta 'sujo' (foi alterado). */
    private _isFieldDirty(fieldName: keyof FModelValidatorTP): boolean {
        return !!this._dirtyFields.find(dirtyField => dirtyField === fieldName)
    }
}
