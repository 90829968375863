import React, { useEffect, useState } from 'react'
import { TimelineCP } from 'submodules/nerit-framework-ui/common/components/timeline/TimelineCP'
import { LayoutPortalPagesCP } from 'app/components/layout-portal-pages/LayoutPortalPagesCP'
import { ListResponseDTO } from 'submodules/nerit-framework-utils/sdk-utils/dtos/response/ListResponseDTO'
import { CustomerPortalUserAppointmentResponseDTO } from 'submodules/neritclin-sdk/services/customer-portal/customer-portal/dtos/response/CustomerPortalUserAppointmentResponseDTO'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'
import { CustomerPortalRequests } from 'submodules/neritclin-sdk/services/customer-portal/customer-portal/CustomerPortalRequests'
import { NotificationHelper } from 'submodules/nerit-framework-ui/common/components/notification/inner/NotificationHelper'
import { DateUtils } from 'submodules/nerit-framework-utils/utils/date/DateUtils'
import { DateFormatEnum } from 'submodules/nerit-framework-utils/utils/enums/DateFormatEnum'
import { ButtonGroupCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonGroupCP'
import { BasicStyleWrapperCP } from 'submodules/nerit-framework-ui/common/components/basic-wrappers/BasicStyleWrapperCP'
import { NeritFrameworkRoutingHelper } from 'submodules/nerit-framework-ui/common/router/utils/NeritFrameworkRoutingHelper'
import { PrivateUserRouter } from 'app/routers/private/PrivateUserRouter'
import { ButtonCancelAppointmentCP } from 'modules/scheduler/components/button-cancel-appointment/ButtonCancelAppointmentCP'

/**
 */
export function ScreenAppointments(): JSX.Element {

    const [userAppointiments, setUserAppointiments] = useState<CustomerPortalUserAppointmentResponseDTO[]>([])
    const userAppointmentsRequest = useRequest<ListResponseDTO<CustomerPortalUserAppointmentResponseDTO>>()
    useEffect(onUserAppointmentsRequestChange, [userAppointmentsRequest.isAwaiting])

    useEffect(init, [])

    /**
     */
    function init(): void {

        userAppointmentsRequest.runRequest(CustomerPortalRequests.searchUserAppointments())
    }

    /**
     */
    function onUserAppointmentsRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(userAppointmentsRequest, NotificationHelper.DEFAULT_ERROR_GET_MESSAGE))
            return

        setUserAppointiments(userAppointmentsRequest.responseData!.list)
    }

    return (
        <LayoutPortalPagesCP
            header={{
                title: 'Meus Agendamentos',
            }}
            content={
                <>
                    <TimelineCP
                        loading={userAppointmentsRequest.isAwaiting}
                        margin={{top: 20}}
                        items={
                            userAppointiments.map((userAppointment) => ({
                                title: `${DateUtils.transformDateStrFormat(DateFormatEnum.US_WITH_TIME_H_M, DateFormatEnum.BR_WITHOUT_TIME, userAppointment.dateRange.beginDate)}: ${DateUtils.transformDateStrFormat(DateFormatEnum.US_WITH_TIME_H_M, DateFormatEnum.TIME_H_M, userAppointment.dateRange.beginDate, )} às ${DateUtils.transformDateStrFormat(DateFormatEnum.US_WITH_TIME_H_M, DateFormatEnum.TIME_H_M, userAppointment.dateRange.endDate)}`,
                                content: (
                                    <>
                                        {userAppointment.sessions.map((session) => session.treatment.name).join(', ')}
                                        <BasicStyleWrapperCP margin={{top: 10}}>
                                            <ButtonGroupCP mode={'separeted'}>
                                                <ButtonCancelAppointmentCP
                                                    sessionCodes={userAppointment.sessions.map((sessionData) => sessionData.sessionCode)}
                                                    mode={'reschedule'}
                                                    onCancel={() => NeritFrameworkRoutingHelper.historyPush(PrivateUserRouter.SCHEDULER)}
                                                    appearance={{
                                                        buttonSize: 'small'
                                                    }}
                                                />
                                                <ButtonCancelAppointmentCP
                                                    sessionCodes={userAppointment.sessions.map((sessionData) => sessionData.sessionCode)}
                                                    mode={'cancel'}
                                                    onCancel={init}
                                                    appearance={{
                                                        buttonSize: 'small'
                                                    }}
                                                />
                                            </ButtonGroupCP>
                                        </BasicStyleWrapperCP>
                                    </>
                                )
                            }))
                        }
                    />
                </>
            }
        />
    )
}
