import React, { useEffect, useState } from 'react'
import { IFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/types/IFormStateManager'
import { IDateRangeFilter } from 'submodules/nerit-framework-ui/common/components/form-fields/date-range-picker/inner/IDateRangeFilter'
import { SchedulerFormModel } from 'modules/scheduler/components/wizard-scheduler/inner/SchedulerFormModel'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'
import { CalendarCP } from 'common/components/calendar/CalendarCP'
import { CustomerPortalSearchAvailableScheduleSlotsRequestDTO } from 'submodules/neritclin-sdk/services/customer-portal/customer-portal/dtos/request/CustomerPortalSearchAvailableScheduleSlotsRequestDTO'
import { CustomerPortalSessionsAvailableSearchResponseDTO } from 'submodules/neritclin-sdk/services/customer-portal/customer-portal/dtos/response/CustomerPortalSessionsAvailableSearchResponseDTO'
import { AppStateUtils } from 'app/redux/AppStateUtils'
import moment from 'moment'
import { CustomerPortalSearchDisabledDatesResponseDTO } from 'submodules/neritclin-sdk/services/customer-portal/customer-portal/dtos/response/CustomerPortalSearchDisabledDatesResponseDTO'
import { CustomerPortalRequests } from 'submodules/neritclin-sdk/services/customer-portal/customer-portal/CustomerPortalRequests'

interface ICPProps {
    onChangeSelectedDate: (selectedDate: Date) => void
    currentDate?: IDateRangeFilter
    formStateManager: IFormStateManager<SchedulerFormModel>
}

/**
 */
export function CalendarToScheduleCP(props: ICPProps): JSX.Element {

    const [disabledDates, setDisabledDates] = useState<Date[]>()
    const searchDisabledDatesRequest = useRequest<CustomerPortalSearchDisabledDatesResponseDTO>()
    useEffect(onSearchDisabledDatesRequestChange, [searchDisabledDatesRequest.isAwaiting])

    useEffect(() => searchDisabledDates(new Date), [])

    /**
     */
    function searchDisabledDates(selectedMonth: Date): void {

        const formValues = props.formStateManager.getFormValues()!
        const searchDto: CustomerPortalSearchAvailableScheduleSlotsRequestDTO = {
            dateRange: {
                beginDate: moment(selectedMonth).startOf('month').add(-1, 'week').toDate(), // Adiciona uma semana antes e uma semana depois, pois em alguns meses aparece o intervalo a mais no mes
                endDate: moment(selectedMonth).endOf('month').add(1, 'week').toDate()
            },
            sessionCodes: formValues.sessions.map((session: CustomerPortalSessionsAvailableSearchResponseDTO) => session.sessionCode),
            customerCode: AppStateUtils.getLoggedUser()!.personCode,
            franchiseCode: formValues.franchiseCode,
        }

        searchDisabledDatesRequest.runRequest(CustomerPortalRequests.searchScheduleDisableDates(searchDto))
    }

    /**
     */
    function onSearchDisabledDatesRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(searchDisabledDatesRequest, 'Erro ao buscar agendamentos.'))
            return

        setDisabledDates(searchDisabledDatesRequest.responseData?.disabledDates)
    }

    return (
        <CalendarCP
            currentDate={props.currentDate}
            onChangeSelectedDate={props.onChangeSelectedDate}
            minDate={moment().add(1, 'day').toDate()}
            disabledDates={disabledDates}
            onChangeNavigation={searchDisabledDates}
            loading={searchDisabledDatesRequest.isAwaiting}
        />
    )
}
