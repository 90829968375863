import React, { useEffect, useState } from 'react'
import { SchedulerFormModel } from 'modules/scheduler/components/wizard-scheduler/inner/SchedulerFormModel'
import { useFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/UseFormStateManager'
import { LayoutPortalPagesCP } from 'app/components/layout-portal-pages/LayoutPortalPagesCP'
import { ResultSchedulerCP } from 'modules/scheduler/components/result-scheduler/ResultSchedulerCP'
import { WizardSchedulerCP } from 'modules/scheduler/components/wizard-scheduler/WizardSchedulerCP'
import { NameAndCodeResponseDTO } from 'submodules/nerit-framework-utils/sdk-utils/dtos/response/NameAndCodeResponseDTO'
import { AppStateUtils } from 'app/redux/AppStateUtils'
import { ArrayUtils } from 'submodules/nerit-framework-utils/utils/ArrayUtils'
import { NotificationHelper } from 'submodules/nerit-framework-ui/common/components/notification/inner/NotificationHelper'
import { EmptyCP } from 'submodules/nerit-framework-ui/common/components/empty/EmptyCP'

export const SCHEDULER_SCREEN_SESSIONS_PARAM = 'sessions'

/**
 */
export function ScreenScheduler(): JSX.Element {

    const [stepView, setStepView] = useState<'wizard' | 'result'>('wizard')

    const [formValidator, setFormValidator] = useState<SchedulerFormModel>()
    const formStateManager = useFormStateManager(formValidator)

    useEffect(init, [])

    /**
     */
    function init(): void {

        const loggedUserFranchises: NameAndCodeResponseDTO[] | undefined = AppStateUtils.getLoggedUser()?.franchises
        if (ArrayUtils.isEmpty(loggedUserFranchises)) {
            NotificationHelper.error('Ops', 'Não encontramos o seu cadastro em nenhuma unidade')
            return
        }

        if (loggedUserFranchises!.length > 1) {
            setFormValidator(new SchedulerFormModel())
            return
        }

        setFormValidator(new SchedulerFormModel({
            franchiseCode: loggedUserFranchises![0].code
        }))
    }

    if (!formStateManager.getFormValues())
        return <EmptyCP description={'Não encontramos nenhuma unidade vinculada'}/>

    return (
        <LayoutPortalPagesCP
            header={{
                title: 'Novo Agendamento',
            }}
            content={
                <>
                    {
                        stepView === 'wizard' &&
                        <WizardSchedulerCP
                            formStateManager={formStateManager}
                            onSave={() => setStepView('result')}
                        />
                    }

                    {
                        stepView === 'result' &&
                        <ResultSchedulerCP
                            formStateManager={formStateManager}
                        />
                    }
                </>
            }
        />
    )
}
