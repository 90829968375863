import styled from 'submodules/nerit-framework-ui/theme/_old/styledWithTheme'
import * as React from 'react'
import profileImgDefault from 'submodules/nerit-framework-ui/common/assets/profile.png'
import { FlexCP } from 'submodules/nerit-framework-ui/common/components/flex/FlexCP'
import { StringUtils } from 'submodules/nerit-framework-utils/utils/StringUtils'
import { TextCP } from 'submodules/nerit-framework-ui/common/components/text/TextCP'
import { useScreenSize } from 'submodules/nerit-framework-ui/common/responsiveness/use-screen-size/UseScreenSize'
import { ThemeProject } from 'config/theme/project/ThemeProject'
import { PopOverCP } from 'submodules/nerit-framework-ui/common/components/pop-over/PopOverCP'
import { UpdateAppVersionCP } from 'submodules/nerit-framework-ui/common/components/system/update-app-version/UpdateAppVersionCP'

interface IProfileMenuItemICPProps {
    profileImage?: string
    profileOptions: Array<{ text: string, action: () => void }>
    optionTitle: string
    isTopMenu?: boolean
}

/**
 * Foto e menu de profile no final do menu.
 */
export function ProfileMenuItemCP(props: IProfileMenuItemICPProps): JSX.Element {

    const screenSize = useScreenSize()

    return (
        <FlexCP>
            {
                props.isTopMenu && !screenSize.smd &&
                <TextCP
                    text={`Bem-vindo(a), ${StringUtils.getFirstName(props.optionTitle)}`}
                    wrap={false}
                    marginRight={10}
                    color={ThemeProject.headerFontColor}
                />
            }
            <PopOverCP
                trigger={'click'}
                placement={'rightTop'}
                title={props.optionTitle}
                content={
                    <OptionsWrapperSCP>
                        {
                            props.profileOptions.map((opt) => (
                                <OptionSCP
                                    onClick={opt.action}
                                    key={opt.text}
                                >
                                    {opt.text}
                                </OptionSCP>
                            ))
                        }

                        <UpdateAppVersionCP/>
                    </OptionsWrapperSCP>
                }
            >
                <ProfileIconSCP isTopMenu={props.isTopMenu}>
                    <StyledProfileIconSCP profileImage={props.profileImage!}/>
                </ProfileIconSCP>
            </PopOverCP>
        </FlexCP>
    )
}

const OptionSCP = styled.p`
    cursor: pointer;
    font-weight: 700;
`

const OptionsWrapperSCP = styled.div`
    box-sizing: border-box;
`

const ProfileIconSCP = styled.div<{ isTopMenu?: boolean }>`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-bottom: ${props => props.isTopMenu ? '' : '20px'};
`

const StyledProfileIconSCP = styled.div<{ profileImage: string }>`
    background-image: url(${props => (props.profileImage ? props.profileImage : profileImgDefault)});
    height: 40px;
    background-size: cover;
    width: 40px;
    border-radius: 100%;
    border: 3px solid ${props => props.theme.white};
    position: relative;
    cursor: pointer;
    :after {
        background: ${props => props.theme.successColor};
        content: '';
        position: absolute;
        width: 11px;
        height: 11px;
        border-radius: 15px;
        bottom: 0;
        right: -4px;
        border: 2px solid #dedede;
    }
`
