import React from 'react'
import { ScreenError404 } from 'submodules/nerit-framework-ui/common/screens/ScreenError404'
import { RouterSwitchCP } from 'submodules/nerit-framework-ui/common/router/components/router-switch/RouterSwitchCP'
import { ScreenHome } from 'app/screens/screen-home/ScreenHome'
import { ScreenScheduler } from 'modules/scheduler/screens/screen-scheduler/ScreenScheduler'
import { ScreenAppointments } from 'modules/scheduler/screens/screen-appointments/ScreenAppointments'
import { ScreenDevTest } from 'app/screens/screen-dev/ScreenDevTest'

PrivateUserRouter.ROOT = 'app'

PrivateUserRouter.HOME = `${PrivateUserRouter.ROOT}/home`
PrivateUserRouter.SCHEDULER = `${PrivateUserRouter.ROOT}/agendamento`
PrivateUserRouter.APPOINTMENTS = `${PrivateUserRouter.ROOT}/meus-agendamentos`

PrivateUserRouter.DEV_TEST = `${PrivateUserRouter.ROOT}/dev-test`

/**
 * Define rotas que so podem ser acessadas apos realizar login na VISAO DE USUARIO.
 * (medico / funcionario de clinica).
 */
export function PrivateUserRouter(): JSX.Element {

    return (
        <RouterSwitchCP
            routes={[
                { path: PrivateUserRouter.HOME, component: () => <ScreenHome/>, exact: true },
                { path: PrivateUserRouter.SCHEDULER, component: () => <ScreenScheduler/>, exact: true },
                { path: PrivateUserRouter.APPOINTMENTS, component: () => <ScreenAppointments/>, exact: true },

                { path: `${PrivateUserRouter.DEV_TEST}`, component: ScreenDevTest, },
                { component: () => <ScreenError404 redirectTo={PrivateUserRouter.HOME}/>, },
            ]}
        />
    )
}
