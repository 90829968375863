import React, { useEffect, useState } from 'react'
import { WizardCP } from 'submodules/nerit-framework-ui/common/components/wizard/WizardCP'
import { IFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/types/IFormStateManager'
import { ArrayUtils } from 'submodules/nerit-framework-utils/utils/ArrayUtils'
import { FormStateManagerUtils } from 'submodules/nerit-framework-ui/common/form-state-manager/FormStateManagerUtils'
import { DateUtils } from 'submodules/nerit-framework-utils/utils/date/DateUtils'
import { DateFormatEnum } from 'submodules/nerit-framework-utils/utils/enums/DateFormatEnum'
import { TimeBaseEnum } from 'submodules/nerit-framework-utils/utils/enums/TimeBaseEnum'
import { WizardSchedulerDateStepICP } from 'modules/scheduler/components/wizard-scheduler/steps/WizardSchedulerDateStepICP'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { WizardSchedulerAttendanceStepICP } from 'modules/scheduler/components/wizard-scheduler/steps/WizardSchedulerAttendanceStepICP'
import { NotificationHelper } from 'submodules/nerit-framework-ui/common/components/notification/inner/NotificationHelper'
import { SchedulerFormModel } from 'modules/scheduler/components/wizard-scheduler/inner/SchedulerFormModel'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'
import { CustomerPortalScheduleSessionsRequestDTO } from 'submodules/neritclin-sdk/services/customer-portal/customer-portal/dtos/request/CustomerPortalScheduleSessionsRequestDTO'
import { DescriptionCustomerAttendancesConfirmCP } from 'modules/scheduler/components/description-customer-attendances-confirm/DescriptionCustomerAttendancesConfirmCP'
import { DateRangeSearchRequestDTO } from 'submodules/nerit-framework-utils/sdk-utils/dtos/request/DateRangeSearchRequestDTO'
import { CustomerPortalRequests } from 'submodules/neritclin-sdk/services/customer-portal/customer-portal/CustomerPortalRequests'
import { CustomerPortalSessionsAvailableSearchResponseDTO } from 'submodules/neritclin-sdk/services/customer-portal/customer-portal/dtos/response/CustomerPortalSessionsAvailableSearchResponseDTO'

interface ICPProps {
    formStateManager: IFormStateManager<SchedulerFormModel>
    onSave: () => void
}

/**
 */
export function WizardSchedulerCP(props: ICPProps): JSX.Element {

    const [step, setStep] = useState<number>(0)
    const doScheduleRequest = useRequest<void>('none')
    useEffect(onDoScheduleRequestChange, [doScheduleRequest.isAwaiting])

    /**
     * Agenda sessoes.
     */
    async function scheduleSessions(): Promise<void> {

        if (!await FormStateManagerUtils.validateRequiredFields(props.formStateManager))
            return

        const formValues = props.formStateManager.getFormValues()!

        const sessions: { code: number, dateRange: DateRangeSearchRequestDTO }[] = []
        let currentDateTime = DateUtils.toDate(formValues.dateInterval.beginDate, DateFormatEnum.US_WITH_TIME_H_M)
        formValues.sessions.forEach((session: CustomerPortalSessionsAvailableSearchResponseDTO) => {

            const endDate = DateUtils.add(currentDateTime, session.sessionDuration!, TimeBaseEnum.MINUTE)
            sessions.push({
                code: session.sessionCode,
                dateRange: {
                    beginDate: new Date(currentDateTime),
                    endDate: new Date(endDate)
                }
            })

            currentDateTime = new Date(endDate)
        })

        const dto: CustomerPortalScheduleSessionsRequestDTO = {
            professionalCode: +formValues.userCodeProfessional,
            sessions: sessions,
            franchiseCode: formValues.franchiseCode,
        }
        doScheduleRequest.runRequest(CustomerPortalRequests.scheduleSessions(dto))
    }

    /**
     * Retorno da API ao agendar sessoes.
     */
    function onDoScheduleRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(doScheduleRequest, NotificationHelper.DEFAULT_ERROR_SAVE_MESSAGE, NotificationHelper.DEFAULT_SUCCESS_SAVE_MESSAGE))
            return

        props.onSave()
    }


    const formValues: any = props.formStateManager.getFormValues();
    console.log("FORMVALUES ", formValues, formValues['franchiseCode']);
    if (!formValues || formValues.franchiseCode == 102) {
        return <></>
    }

    return (
        <WizardCP
            loading={doScheduleRequest.isAwaiting}
            currentStep={step}
            onChangeStep={setStep}
            action={scheduleSessions}
            steps={[
                {
                    key: 'key-attendances',
                    title: 'Qual área deseja agendar?',
                    canContinue: () => !ArrayUtils.isEmpty(props.formStateManager.getFieldValue('sessions')),
                    content: (
                        <WizardSchedulerAttendanceStepICP
                            formStateManager={props.formStateManager}
                            loggedCustomerCode={189}
                        />
                    )
                },
                {
                    key: 'key-calendar',
                    title: 'Qual melhor dia/horário?',
                    hideNexButton: true,
                    content: (
                        <WizardSchedulerDateStepICP
                            formStateManager={props.formStateManager}
                            onSelectDateTime={() => setStep(step + 1)}
                        />
                    )
                },
                {
                    key: 'key-confirm',
                    title: 'Confirmação',
                    content: (
                        <DescriptionCustomerAttendancesConfirmCP
                            dateInterval={props.formStateManager.getFieldValue('dateInterval')}
                            treatmentsNames={props.formStateManager.getFieldValue('sessions')?.map((session: CustomerPortalSessionsAvailableSearchResponseDTO) => session.treatment.name)}
                        />
                    )
                },
            ]}
        />
    )



}
