import React from 'react'
import { TextCP } from 'submodules/nerit-framework-ui/common/components/text/TextCP'
import { DescriptionCP } from 'submodules/nerit-framework-ui/common/components/description/DescriptionCP'
import { DateUtils } from 'submodules/nerit-framework-utils/utils/date/DateUtils'
import { DateFormatEnum } from 'submodules/nerit-framework-utils/utils/enums/DateFormatEnum'
import { DateRangeStringResponseDTO } from 'submodules/nerit-framework-utils/sdk-utils/dtos/response/DateRangeStringResponseDTO'

interface ICPProps {
    dateInterval: DateRangeStringResponseDTO
    treatmentsNames: string[]
}

/**
 */
export function DescriptionCustomerAttendancesConfirmCP(props: ICPProps): JSX.Element {

    return (
        <DescriptionCP
            style={{
                padding: '15px 10px'
            }}
            items={[
                {
                    label: 'Dia',
                    text: DateUtils.getFormatted(DateUtils.toDate(props.dateInterval.beginDate!, DateFormatEnum.US_WITH_TIME_H_M), DateFormatEnum.BR_WITHOUT_TIME)
                },
                {
                    label: 'Horário',
                    text: `${DateUtils.getFormatted(DateUtils.toDate(props.dateInterval.beginDate!, DateFormatEnum.US_WITH_TIME_H_M), DateFormatEnum.TIME_H_M)} às ${DateUtils.getFormatted(DateUtils.toDate(props.dateInterval.endDate!, DateFormatEnum.US_WITH_TIME_H_M), DateFormatEnum.TIME_H_M)}`
                },
                {
                    label: 'Atendimentos',
                    text: (
                        <>
                            {
                                props.treatmentsNames.map((treatmentName) => (
                                    <TextCP
                                        icon={'check-circle'}
                                        text={treatmentName}
                                    />
                                ))
                            }
                        </>
                    )
                },
            ]}
        />
    )
}
