import React from 'react'

interface IConditionalRenderCPProps {
    shouldRender: boolean
    children: JSX.Element | JSX.Element[] | string
}

/**
 * Wrapper para renderizar um componente filho ou nao.
 */
export function ConditionalRenderCP(props: IConditionalRenderCPProps): JSX.Element {

    if (props.shouldRender)
        return <>{ props.children }</>

    return (
        <></>
    )
}
