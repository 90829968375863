import * as _ from 'lodash'
import React, { useEffect, useState } from 'react'
import Calendar from 'react-calendar'
import styled from 'styled-components'
import { DateUtils } from 'submodules/nerit-framework-utils/utils/date/DateUtils'
import { IDateRangeFilter } from 'submodules/nerit-framework-ui/common/components/form-fields/date-range-picker/inner/IDateRangeFilter'
import { IconICP } from 'submodules/nerit-framework-ui/common/components/icon/inner/IconICP'
import { ArrayUtils } from 'submodules/nerit-framework-utils/utils/ArrayUtils'
import { DateFormatEnum } from 'submodules/nerit-framework-utils/utils/enums/DateFormatEnum'
import { LoadingOverlayCP } from 'submodules/nerit-framework-ui/common/components/loading/overlay/LoadingOverlayCP'

type _ViewTP = 'month' | 'year' | 'decade' | 'century'

interface ICPProps {
    currentDate?: IDateRangeFilter
    onChangeSelectedDate: (selectedDate: Date) => void
    minDate?: Date
    disabledDates?: Date[]
    loading?: boolean
    onChangeNavigation?: (date: Date) => void
}

/**
 * Calendario para selecao de data.
 */
export function CalendarCP(props: ICPProps): JSX.Element {

    const [calendarDate, setCalendarDate] = useState<Date[]>([new Date()])
    useEffect(init, [props.currentDate])

    /**
     * Inicializa.
     */
    function init(): void {

        if (!props.currentDate?.beginDate)
            return

        const dates = [props.currentDate.beginDate]
        if (!!props.currentDate.endDate)
            dates.push(props.currentDate.endDate)

        setCalendarDate(dates)
    }

    /**
     * Ao escolher uma data.
     */
    function onChangeCalendarValue(selectedDate: Date): void {

        props.onChangeSelectedDate(selectedDate)
        setCalendarDate(DateUtils.getAllDatesInInterval(selectedDate))
    }

    /**
     *  Funcao para mudar o label exibido pelo componente (Nome do mes, etc.)
     */
    function onChangeNavigation(date: Date, view: _ViewTP, label: string): string {

        if (view === 'month') {
            const monthLabel = label.split(' ')[0]
            return _.capitalize(monthLabel)
        }

        return label
    }

    /**
     */
    function onTileDisabled(date: Date): boolean {

        // Se nao estiver configurado datas desabilitadas, sempre retorna como habilitada
        if (ArrayUtils.isEmpty(props.disabledDates))
            return false

        const foundDisabledDate = props.disabledDates?.find((eachDate) => DateUtils.formatDate(date, DateFormatEnum.US_WITHOUT_TIME) === DateUtils.formatDate(eachDate, DateFormatEnum.US_WITHOUT_TIME))
        return !!foundDisabledDate
    }

    return (
        <CalendarWrapperSCP>
            <LoadingOverlayCP show={props.loading ?? false}/>

            <Calendar
                value={calendarDate as Date[]}
                locale={'pt-BR'}
                calendarType={'US'}
                navigationLabel={({ date, view, label }) => onChangeNavigation(date, view, label)}
                prevLabel={<IconICP iconName={'arrow-left'}/>}
                nextLabel={<IconICP iconName={'arrow-right'}/>}
                onChange={(value) => onChangeCalendarValue(value as Date)}
                minDate={props.minDate}
                tileDisabled={(date) => onTileDisabled(date.date)}
                onActiveDateChange={(a) => {
                    if (!!props.onChangeNavigation)
                        props.onChangeNavigation(a.activeStartDate)
                }}
            />
        </CalendarWrapperSCP>
    )
}

const CalendarWrapperSCP = styled.div`
  position: relative;

  .react-calendar {
    border: none;
    padding: 10px;
    background-color: transparent;
  }

  .react-calendar__navigation {
    margin-bottom: 0;
  }

  .react-calendar__navigation__label {
    font-weight: 700;
    font-size: 1.15rem;
    color: ${props => props.theme.primaryColor};
  }

  .react-calendar__month-view {
    padding-top: 1.2rem;
    border-top: 1px solid;
  }

  .react-calendar__tile--active:enabled:hover, .react-calendar__tile--active:enabled:focus {
    background-color: ${props => props.theme.primaryColor};
  }

  .react-calendar__navigation__next2-button, .react-calendar__navigation__prev2-button {
    display: none !important;
  }

  .react-calendar__navigation button:enabled:hover, .react-calendar__navigation button:enabled:focus {
    background-color: transparent;
  }

  .react-calendar__tile .react-calendar__month-view__days__day {
    font-weight: 600 !important;
  }

  .react-calendar__month-view__days__day--neighboringMonth {
    color: #8e8e8e !important;
  }

  .react-calendar__month-view__weekdays__weekday {

    text-transform: capitalize;

    abbr {
      text-decoration: none;
      color: ${props => props.theme.primaryColor};
      font-size: 12px;
      font-weight: 900;
    }
  }

  .react-calendar__month-view__days button {
    font-weight: 600;
  }

  .react-calendar__tile, .react-calendar__month-view__days__day {
    color: ${props => props.theme.primaryColor};
    position: relative;
  }

  .react-calendar__tile--active {
    //border-radius: 100px;
    color: white;
      //background-color: ${props => props.theme.primaryColor};
  }

  .react-calendar__tile--now:not(.react-calendar__tile--active)::after {
    content: '';
    position: absolute;
    height: 2px;
    width: 19px;
    background-color: ${props => props.theme.primaryColor};
    bottom: 7px;
    margin: 0 auto;
    right: 0;
    left: 0;
  }

  .react-calendar__tile:disabled {
    position: relative;
  }

  .react-calendar__tile:disabled::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    height: 1px;
    background-color: #ccc;
    transform: rotate(-45deg);
  }
`
