import React from 'react'
import { IFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/types/IFormStateManager'
import { SelectCP } from 'submodules/nerit-framework-ui/common/components/form-fields/select/SelectCP'
import { AppStateUtils } from 'app/redux/AppStateUtils'

interface IFranchiseSelectCPProps {
    formStateManager: IFormStateManager<any>
    fieldName: string
}

/**
 * COMPONENTE Select de Franquias
 */
export function SelectFranchiseFromLoggedUserCP(props: IFranchiseSelectCPProps): JSX.Element {

    return (
        <SelectCP
            options={AppStateUtils.getLoggedUser()?.franchises.map((franchise) => ({
                label: franchise.name,
                value: franchise.code
            })) ?? []}
            label={'Selecione uma unidade'}
            formStateManager={props.formStateManager}
            fieldName={props.fieldName}
        />
    )
}
