import { ReduxHelper } from 'app/redux/helpers/ReduxHelper'
import { RequestHelper } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestHelper'
import { NotificationHelper } from 'submodules/nerit-framework-ui/common/components/notification/inner/NotificationHelper'
import { NeritFrameworkRoutingHelper } from 'submodules/nerit-framework-ui/common/router/utils/NeritFrameworkRoutingHelper'
import { PublicRouter } from 'app/routers/public/PublicRouter'
import { AppStateUtils } from 'app/redux/AppStateUtils'
import { AuthUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/utils/AuthUtils'
import { REDUCER_LOGGED_USER, REDUCER_UPDATE_DOMAIN } from 'app/redux/Reducers'
import { ISchemaResponseDTO } from 'app/services/dtos/response/ISchemaResponseDTO'
import { CustomerAuthDataResponseDTO } from 'submodules/neritclin-sdk/services/customer-portal/auth/dtos/responses/CustomerAuthDataResponseDTO'

/**
 * Encapsula definicao de acoes envolvendo estado global (redux) relativas ao modulo de AUTENTICACAO.
 */
export class AuthActions {

    private static handlingUnauthorizedError = false

    /**
     * Encapsula procedimento completo de 'logar' 01 usuario do sistama.
     */
    static setLoggedUser(customer: CustomerAuthDataResponseDTO, token: string): void {

        ReduxHelper.getInstance().store.dispatch({
            type: REDUCER_LOGGED_USER,
            payload: {customer, token}
        })

        this.refreshToken(token!)
    }

    /**
     * Encapsula procedimento completo de 'deslogar' 01 usuario.
     */
    static logout(): void {

        ReduxHelper.getInstance().store.dispatch({ type: REDUCER_LOGGED_USER, payload: null })
        this.refreshToken(null)

        NeritFrameworkRoutingHelper.historyPush(PublicRouter.LOGIN)
    }

    /** Atualiza dados do cliente (schema). */
    static setDomain(schema: ISchemaResponseDTO): void {

        RequestHelper.addDefaultHeader('schema', schema.slug)

        ReduxHelper.getInstance().store.dispatch({
            type: REDUCER_UPDATE_DOMAIN,
            payload: { ...schema }
        })

    }

    /**
     */
    static onUnauthorizedRequestResponse(): void {

        if (this.handlingUnauthorizedError)
            return

        if (!!AppStateUtils.getAuthData()) {
            this.handlingUnauthorizedError = true
            const notificationDuration = 6
            NotificationHelper.info('Sessão Expirada!', 'Sua sessão expirou. Faça login novamente para prosseguir', notificationDuration)
            setTimeout(() => AuthActions.handlingUnauthorizedError = false, notificationDuration - 1)
        }

        this.logout()
    }

    /**
     * Atualiza propriedades estaticas que guardam o token de autenticacao do usuario atual sincronizando-as com o conteudo mantido no estado global da aplicacao (redux).
     */
    static refreshToken(authToken: string | null): void {

        if (!authToken)
            return

        RequestHelper.addDefaultHeaderConfig(AuthUtils.getBearerAuthHeaderConfig(authToken))
    }

}
