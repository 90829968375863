import React from 'react'
import { ContentCP } from 'submodules/nerit-framework-ui/common/components/screen/layout/content/ContentCP'
import styled from 'styled-components'
import { LayoutCP } from 'submodules/nerit-framework-ui/common/components/screen/layout/layout/LayoutCP'
import { FlexCP } from 'submodules/nerit-framework-ui/common/components/flex/FlexCP'
import { LogoCP } from 'submodules/nerit-framework-ui/common/components/logo/LogoCP'
import { HeaderPrivatePagesCP } from 'app/components/header-logged-user/HeaderPrivatePagesCP'
import { AppStateUtils } from 'app/redux/AppStateUtils'
import { TopBarHomEnvironmentCP } from 'submodules/nerit-framework-ui/common/components/system/top-bar-hom-environment/TopBarHomEnvironmentCP'

interface ICPProps {
    header: {
        title: string
    }
    content: React.ReactNode
}

/**
 */
export function LayoutPortalPagesCP(props: ICPProps): JSX.Element {

    return (
        <LayoutCP
            backgroundColor={'#fff'}
            header={
                <>
                    <TopBarHomEnvironmentCP/>
                    <HeaderPrivatePagesCP
                        title={props.header.title}
                    />
                </>
            }
            content={
                <ContentCP overflowVertical={true}>

                    <WrapperSCP>
                        { props.content }
                    </WrapperSCP>

                    <FlexCP
                        margin={{ top: 30 }}
                        alignItems={'center'}
                        justifyContent={'center'}
                    >
                        <i>Powered by</i>
                        <LogoCP
                            height={30}
                            marginLeft={10}
                            marginTop={0}
                            marginBottom={0}
                            imgUrl={AppStateUtils.getDomainData()?.logoUrl ?? `${process.env.PUBLIC_URL}/logo-sistema.png`}
                        />
                    </FlexCP>
                </ContentCP>
            }
        />
    )
}

const WrapperSCP = styled.div`
  padding: 20px;
`
