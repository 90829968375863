import React, { useEffect, useState } from 'react'
import { TextCP } from 'submodules/nerit-framework-ui/common/components/text/TextCP'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import { IFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/types/IFormStateManager'
import { IDateRangeFilter } from 'submodules/nerit-framework-ui/common/components/form-fields/date-range-picker/inner/IDateRangeFilter'
import { LoadingOverlayCP } from 'submodules/nerit-framework-ui/common/components/loading/overlay/LoadingOverlayCP'
import styled from 'styled-components'
import * as _ from 'lodash'
import { SchedulerFormModel } from 'modules/scheduler/components/wizard-scheduler/inner/SchedulerFormModel'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { DateFormatEnum } from 'submodules/nerit-framework-utils/utils/enums/DateFormatEnum'
import { DateUtils } from 'submodules/nerit-framework-utils/utils/date/DateUtils'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'
import { RowCP } from 'submodules/nerit-framework-ui/common/components/grid/row/RowCP'
import { ColumnCP } from 'submodules/nerit-framework-ui/common/components/grid/column/ColumnCP'
import { ListResponseDTO } from 'submodules/nerit-framework-utils/sdk-utils/dtos/response/ListResponseDTO'
import { CustomerPortalSearchAvailableScheduleSlotsResponseDTO } from 'submodules/neritclin-sdk/services/customer-portal/customer-portal/dtos/response/CustomerPortalSearchAvailableScheduleSlotsResponseDTO'
import { FlexCP } from 'submodules/nerit-framework-ui/common/components/flex/FlexCP'
import { AlertCP } from 'submodules/nerit-framework-ui/common/components/alert/AlertCP'
import { CustomerPortalSearchAvailableScheduleSlotsRequestDTO } from 'submodules/neritclin-sdk/services/customer-portal/customer-portal/dtos/request/CustomerPortalSearchAvailableScheduleSlotsRequestDTO'
import { CustomerPortalSessionsAvailableSearchResponseDTO } from 'submodules/neritclin-sdk/services/customer-portal/customer-portal/dtos/response/CustomerPortalSessionsAvailableSearchResponseDTO'
import { CustomerPortalRequests } from 'submodules/neritclin-sdk/services/customer-portal/customer-portal/CustomerPortalRequests'
import { DayPeriodEnum } from 'submodules/nerit-framework-utils/utils/date/DayPeriodEnum'
import { RadioGroupDayPeriodCP } from 'submodules/nerit-framework-ui/features/calendar/components/radio-group-day-period/RadioGroupDayPeriodCP'
import { AppStateUtils } from 'app/redux/AppStateUtils'
import { DateRangeStringResponseDTO } from 'submodules/nerit-framework-utils/sdk-utils/dtos/response/DateRangeStringResponseDTO'
import { CalendarToScheduleCP } from 'modules/scheduler/components/calendar-to-schedule/CalendarToScheduleCP'

interface ICPProps {
    formStateManager: IFormStateManager<SchedulerFormModel>
    onSelectDateTime: () => void
}

/**
 */
export function WizardSchedulerDateStepICP(props: ICPProps): JSX.Element {

    const [date, setDate] = useState<IDateRangeFilter>()
    const [period, setPeriod] = useState<DayPeriodEnum>(DayPeriodEnum.ALL)

    const [availableSlotsByProfessional, setAvailableSlotsByProfessional] = useState<CustomerPortalSearchAvailableScheduleSlotsResponseDTO[]>()
    const searchAvailableSlotsRequest = useRequest<ListResponseDTO<CustomerPortalSearchAvailableScheduleSlotsResponseDTO>>()
    useEffect(onSearchAvailableSlotsRequestChange, [searchAvailableSlotsRequest.isAwaiting])

    useEffect(searchSlots, [period])

    /**
     */
    function searchSlots(selectedDate?: IDateRangeFilter): void {

        // Se vier em branco, selecionou o turno
        let selectedDateWithPeriod: IDateRangeFilter | undefined = selectedDate ?? date
        if (!selectedDateWithPeriod)
            return

        const dateWithPeriod = DateUtils.formatByPeriod(selectedDateWithPeriod, period)
        setDate(dateWithPeriod)

        const formValues = props.formStateManager.getFormValues()!
        const searchDto: CustomerPortalSearchAvailableScheduleSlotsRequestDTO = {
            dateRange: dateWithPeriod,
            sessionCodes: formValues.sessions.map((session: CustomerPortalSessionsAvailableSearchResponseDTO) => session.sessionCode),
            customerCode: AppStateUtils.getLoggedUser()!.personCode,
            franchiseCode: formValues.franchiseCode,
        }
        searchAvailableSlotsRequest.runRequest(CustomerPortalRequests.searchScheduleAvailableSlots(searchDto))
    }

    /**
     */
    function onSearchAvailableSlotsRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(searchAvailableSlotsRequest, 'Erro ao buscar agendamentos.'))
            return

        setAvailableSlotsByProfessional(searchAvailableSlotsRequest.responseData?.list)
    }

    /**
     */
    function formatTime(availableSlot: DateRangeStringResponseDTO): string {

        const initTime = DateUtils.transformDateStrFormat(DateFormatEnum.US_WITH_TIME_H_M, DateFormatEnum.TIME_H_M, availableSlot.beginDate)
        const endTime = DateUtils.transformDateStrFormat(DateFormatEnum.US_WITH_TIME_H_M, DateFormatEnum.TIME_H_M, availableSlot.endDate)

        return `${initTime} às ${endTime} `
    }

    return (
        <>
            <LoadingOverlayCP show={searchAvailableSlotsRequest.isAwaiting}/>

            <RowCP>
                <ColumnCP size={24} lg={12} md={12}>
                    <CalendarToScheduleCP
                        currentDate={date}
                        onChangeSelectedDate={(date) => searchSlots({ beginDate: date, endDate: date }) }
                        formStateManager={props.formStateManager}
                    />

                    <FlexCP justifyContent={'flex-start'}>
                        <TextCP text={`Tempo total de atendimento: ${_.sumBy(props.formStateManager.getFieldValue('sessions'), 'sessionDuration')}min`}/>
                    </FlexCP>

                    <FlexCP justifyContent={'flex-start'} margin={{ top: 20 }}>
                        <RadioGroupDayPeriodCP
                            value={period}
                            onChange={setPeriod}
                        />
                    </FlexCP>
                </ColumnCP>
                <ColumnCP size={24} lg={12} md={12}>

                    <WrapperButtonsSCP>
                        {
                            availableSlotsByProfessional?.map((professionalSlots) =>
                                professionalSlots.availableSlots.map((availableSlot) => (
                                    <ButtonCP
                                        icon={'calendar'}
                                        type={(professionalSlots.userProfessional.code === props.formStateManager.getFieldValue('userCodeProfessional') && availableSlot === props.formStateManager.getFieldValue('dateInterval')) ? 'primary' : undefined}
                                        onClick={() => {
                                            props.formStateManager.changeFieldValue('userCodeProfessional', professionalSlots?.userProfessional.code)
                                            props.formStateManager.changeFieldValue('dateInterval', availableSlot)
                                            props.onSelectDateTime()
                                        }}
                                        confirmMsg={`Tem certeza que deseja agendar nesse horário? (${ formatTime(availableSlot) })`}
                                    >
                                        { formatTime(availableSlot) }
                                    </ButtonCP>
                                ))
                            )
                        }
                    </WrapperButtonsSCP>

                    {
                        !!availableSlotsByProfessional &&
                        <AlertCP
                            margin={{ top: 20 }}
                            message={'Não achou o horário desejado, tente selecionar menos áreas'}
                            type={'warning'}
                        />
                    }
                </ColumnCP>
            </RowCP>
        </>
    )
}

const WrapperButtonsSCP = styled.div`
  padding: 20px 0;

  button {
    margin-bottom: 10px;
    width: 100%;
    justify-content: center;
  }
`
