import { Layout } from 'antd'
import React from 'react'
import styled from 'styled-components'
import { ThemeProject } from 'config/theme/project/ThemeProject'
import { BasicStyleWrapperCPProps } from 'submodules/nerit-framework-ui/common/components/basic-wrappers/BasicStyleWrapperCP'

type _ScpPropsTP = {
    isMainSider?: boolean,
    bgColor?: string,
    width?: number | string,
    overflowY?: 'auto'
}

type _AntPropsTP = {
    collapsedWidth?: number | string,
    breakpoint?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl',
    zeroWidthTriggerStyle?: React.CSSProperties,
    onCollapse?: () => void,
    trigger?: JSX.Element | null,
    collapsed?: boolean,
    collapsible?: boolean,
    id?: string
}

interface ISiderCPProps extends React.PropsWithChildren<_ScpPropsTP & _AntPropsTP>, BasicStyleWrapperCPProps { }

/**
 * Barra lateral esquerda generica para compor layouts.
 */
export function SiderCP(props: ISiderCPProps): JSX.Element {

    return (
        <WrapperSCP
            isMainSider={props.isMainSider}
            width={props.width}
            bgColor={props.bgColor}
            id={props.id}
            overflowY={props.overflowY}
            margin={props.margin}
        >
            <Layout.Sider
                collapsedWidth={props.collapsedWidth}
                breakpoint={props.breakpoint}
                zeroWidthTriggerStyle={props.zeroWidthTriggerStyle}
                onCollapse={props.onCollapse}
                trigger={props.trigger}
                collapsed={props.collapsed}
                collapsible={props.collapsible}
                width={'100%'}
            >
                {props.children}
            </Layout.Sider>
        </WrapperSCP>
    )
}

const WrapperSCP = styled.div<_ScpPropsTP & BasicStyleWrapperCPProps>`

  height: ${props => (!!props.isMainSider ? '100vh' : '100%')};
  width: ${props => ((typeof props.width === 'string') ? props.width : (!!props.width ? `${props.width}px` : 'unset'))};

  margin-left: ${props => props.margin?.left ?? 0}px;
  margin-right: ${props => props.margin?.right ?? 0}px;
  margin-bottom: ${props => props.margin?.bottom ?? 0}px;
  margin-top: ${props => props.margin?.top ?? 0}px;

  .ant-layout-sider {

    position: static;
    overflow-y: ${props => props.overflowY};

    height: 100%;
    background: ${props => props.bgColor ?? ThemeProject.primaryMenuColor};

    .ant-layout-sider-children {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }
`
