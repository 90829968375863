import { Radio } from 'antd'
import { RadioChangeEvent } from 'antd/lib/radio'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { RadioOptionTP } from './inner/RadioOptionTP'
import * as _ from 'lodash'
import { FormModel } from 'submodules/nerit-framework-ui/common/form-state-manager/types/FormModel'
import { IFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/types/IFormStateManager'
import { TooltipCP } from 'submodules/nerit-framework-ui/common/components/tooltip/TooltipCP'
import { FontSizeTP } from 'submodules/nerit-framework-ui/theme/_old/types/ThemeTypes'
import { ConditionalRenderCP } from 'submodules/nerit-framework-ui/common/components/conditional-render/ConditionalRenderCP'
import { ThemeProject } from 'config/theme/project/ThemeProject'
import { BasicStyleWrapperCP, BasicStyleWrapperCPProps } from 'submodules/nerit-framework-ui/common/components/basic-wrappers/BasicStyleWrapperCP'
import { LoadingOverlayCP } from 'submodules/nerit-framework-ui/common/components/loading/overlay/LoadingOverlayCP'

type _AntPropsTP = {
    disabled?: boolean,
}

type _ScpPropsTP = {
    buttonWidth?: number,
    buttonHeight?: number,
    secondary?: boolean,
    paddingTop?: number,
    fontSize?: FontSizeTP,
    type: 'button' | 'vertical-radio',
}

type _CustomPropsTP<OptionTP, FModelTP extends FormModel> = {
    selected?: OptionTP,
    onChange?: (value: OptionTP) => void,
    fieldName?: keyof FModelTP,
    formStateManager?: IFormStateManager<FModelTP>,
    options: Array<RadioOptionTP<OptionTP>>,
    label?: string,
}

interface IRadioGroupCPProps<OptionTP = string, FModelTP extends FormModel = any>
    extends _AntPropsTP, _ScpPropsTP, _CustomPropsTP<OptionTP, FModelTP>, BasicStyleWrapperCPProps {
    loading?: boolean
}

/**
 * Grupo de radio-butons (combo de selecao unica).
 */
export function RadioGroupCP<OptionTP = string, FModelTP extends FormModel = any>(props: IRadioGroupCPProps<OptionTP>): JSX.Element | null {

    const hasStateManager = (!!props.formStateManager && !!props.fieldName)
    const validationsCount = props.formStateManager?.validationsCount ?? 0

    const [validationErrMsg, setValidationErrMsg] = useState<string>()
    const [errorMessage, setErrorMessage] = useState<string>()

    useEffect(parseValidation, [validationsCount])
    useEffect(handleErrMsgUpdate, [validationErrMsg])

    function handleChange(event: RadioChangeEvent): void {

        const nextValue = event.target.value

        if (!!props.onChange)
            props.onChange(nextValue)

        else if (hasStateManager)
            props.formStateManager!.changeFieldValue(props.fieldName!, nextValue)
    }

    function handleErrMsgUpdate(): void {
        setErrorMessage(validationErrMsg)
    }

    function parseValidation(): void {

        if (!hasStateManager || props.formStateManager!.isValid)
            return setValidationErrMsg(undefined)

        const fieldErrors = props.formStateManager!.getFieldError(props.fieldName as keyof FModelTP)
        const constraints = _.get(fieldErrors, 'constraints')

        if (!!constraints) {
            const errMessages: any = Object.values(constraints) || []
            if (!!errMessages.length)
                return setValidationErrMsg(errMessages[0])
        }

        setValidationErrMsg(undefined)
    }

    return (
        <BasicStyleWrapperCP margin={props.margin}>
            <>
                {
                    !!props.label &&
                    <LabelSCP>{props.label}</LabelSCP>
                }

                <RadioGroupWrapperSCP
                    buttonWidth={props.buttonWidth}
                    buttonHeight={props.buttonHeight}
                    secondary={props.secondary}
                    paddingTop={props.paddingTop}
                    fontSize={props.fontSize}
                    type={props.type}
                >
                    <LoadingOverlayCP show={props.loading ?? false}/>
                    <Radio.Group
                        value={hasStateManager ? props.formStateManager?.getFieldValue(props.fieldName!) : props.selected}
                        disabled={props.disabled}
                        onChange={handleChange}
                    >
                        {
                            props.options.filter((opt) => !opt.hide).map((opt, index) => (
                                <TooltipCP
                                    text={opt.tooltip}
                                    key={`opt-radio-group-${((opt.value ?? '') as string || index).toString()}`}
                                    placement={props.type === 'vertical-radio' ? 'right' : undefined}
                                >
                                    {
                                        props.type === 'button' &&
                                        <Radio.Button value={opt.value} disabled={opt.disabled}>
                                            {opt.content}
                                        </Radio.Button>
                                    }
                                    {
                                        props.type === 'vertical-radio' &&
                                        <Radio value={opt.value} disabled={opt.disabled}>
                                            {opt.content}
                                        </Radio>
                                    }
                                </TooltipCP>
                            ))
                        }
                    </Radio.Group>
                    <ConditionalRenderCP shouldRender={!!errorMessage}>
                        <ErrorSCP>
                            { errorMessage }
                        </ErrorSCP>
                    </ConditionalRenderCP>
                </RadioGroupWrapperSCP>
            </>
        </BasicStyleWrapperCP>
    )
}

const LabelSCP = styled.div`
  color: ${props => props.theme.darkGray};
`

const RadioGroupWrapperSCP = styled.div<_ScpPropsTP>`

  .ant-radio-group {
    display: ${props => props.type === 'button' ? 'flex' : undefined};
    padding-top: ${props => props.paddingTop ?? '7'}px;
  }

  .ant-radio-wrapper {
    display: block;
    height: 30px;
    line-height: 30px;
  }
  
  .ant-radio-button-wrapper {

    display: flex;
    width: ${props => (!!props.buttonWidth ? `${props.buttonWidth}px` : 'unset')};
    height: ${props => (!!props.buttonHeight ? `${props.buttonHeight}px` : 'unset')};
    align-items: center;
    justify-content: center;

    &.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
      background: ${ThemeProject.checkedBackgroundColor};
      color: ${ThemeProject.checkedColor};
    }

  }
`

const ErrorSCP = styled.div`
  transition: opacity .3s;
  font-size: 12px;
  font-style: italic;
  margin: 2px 0;
  color: ${props => props.theme.errorColor};

  i {
    margin-left: 5px;
  }
`
