import { SessionCodesRequestDTO } from 'submodules/neritclin-sdk/services/sessions/dtos/requests/SessionCodesRequestDTO'
import { ListResponseDTO } from 'submodules/nerit-framework-utils/sdk-utils/dtos/response/ListResponseDTO'
import { HttpMethodEnum } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/enums/HttpMethodEnum'
import { RequestConfigTP } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/types/RequestConfigTP'
import { NeritClinSystemApiEnum } from 'submodules/neritclin-sdk/common/NeritClinSystemApiEnum'
import { CustomerPortalScheduleSessionsRequestDTO } from 'submodules/neritclin-sdk/services/customer-portal/customer-portal/dtos/request/CustomerPortalScheduleSessionsRequestDTO'
import { CustomerPortalSearchAvailableScheduleSlotsRequestDTO } from 'submodules/neritclin-sdk/services/customer-portal/customer-portal/dtos/request/CustomerPortalSearchAvailableScheduleSlotsRequestDTO'
import { CustomerPortalSearchAvailableScheduleSlotsResponseDTO } from 'submodules/neritclin-sdk/services/customer-portal/customer-portal/dtos/response/CustomerPortalSearchAvailableScheduleSlotsResponseDTO'
import { CustomerPortalSessionsAvailableSearchResponseDTO } from 'submodules/neritclin-sdk/services/customer-portal/customer-portal/dtos/response/CustomerPortalSessionsAvailableSearchResponseDTO'
import { CustomerPortalUserAppointmentResponseDTO } from 'submodules/neritclin-sdk/services/customer-portal/customer-portal/dtos/response/CustomerPortalUserAppointmentResponseDTO'
import { CustomerPortalSearchScheduleAvailableSlotsRequestDTO } from 'submodules/neritclin-sdk/services/customer-portal/customer-portal/dtos/request/CustomerPortalSearchScheduleAvailableSlotsRequestDTO'
import { CustomerPortalSearchDisabledDatesResponseDTO } from 'submodules/neritclin-sdk/services/customer-portal/customer-portal/dtos/response/CustomerPortalSearchDisabledDatesResponseDTO'

export class CustomerPortalRequests {

	public static CONTROLLER_ROOT = 'customer-portal'
	public static SEARCH_USER_APPOINTMENTS_EP = 'search-user-appointments'
	public static SEARCH_SESSIONS_AVAILABLE_EP = 'search-sessions-available'
	public static SEARCH_SCHEDULE_AVAILABLE_SLOTS_EP = 'search-schedule-available-slots'
	public static SEARCH_SCHEDULE_DISABLE_DATES_EP = 'search-schedule-disable-dates'
	public static SCHEDULE_SESSIONS_EP = 'schedule-sessions'
	public static CANCEL_USER_APPOINTMENT_EP = 'cancel-user-appointment'
	public static PRESENCE_CONFIRMATION_EP = 'presence-confirmation/:token'

	static searchSessionsAvailable = (dto: CustomerPortalSearchScheduleAvailableSlotsRequestDTO): RequestConfigTP<ListResponseDTO<CustomerPortalSessionsAvailableSearchResponseDTO>> => ({
		baseUrlType: NeritClinSystemApiEnum.NERITCLIN_API,
		url: `${CustomerPortalRequests.CONTROLLER_ROOT}/${CustomerPortalRequests.SEARCH_SESSIONS_AVAILABLE_EP}`,
		method: HttpMethodEnum.GET,
        params: dto
	})

	static searchScheduleAvailableSlots = (dto: CustomerPortalSearchAvailableScheduleSlotsRequestDTO): RequestConfigTP<ListResponseDTO<CustomerPortalSearchAvailableScheduleSlotsResponseDTO>> => ({
		baseUrlType: NeritClinSystemApiEnum.NERITCLIN_API,
		url: `${CustomerPortalRequests.CONTROLLER_ROOT}/${CustomerPortalRequests.SEARCH_SCHEDULE_AVAILABLE_SLOTS_EP}`,
		method: HttpMethodEnum.GET,
		params: dto
	})

	static searchScheduleDisableDates = (dto: CustomerPortalSearchAvailableScheduleSlotsRequestDTO): RequestConfigTP<CustomerPortalSearchDisabledDatesResponseDTO> => ({
		baseUrlType: NeritClinSystemApiEnum.NERITCLIN_API,
		url: `${CustomerPortalRequests.CONTROLLER_ROOT}/${CustomerPortalRequests.SEARCH_SCHEDULE_DISABLE_DATES_EP}`,
		method: HttpMethodEnum.GET,
		params: dto
	})

	static scheduleSessions = (dto: CustomerPortalScheduleSessionsRequestDTO): RequestConfigTP<void> => ({
		baseUrlType: NeritClinSystemApiEnum.NERITCLIN_API,
		url: `${CustomerPortalRequests.CONTROLLER_ROOT}/${CustomerPortalRequests.SCHEDULE_SESSIONS_EP}`,
		method: HttpMethodEnum.POST,
		params: dto
	})

	static confirmPresence = (token: string): RequestConfigTP<void> => ({
		baseUrlType: NeritClinSystemApiEnum.NERITCLIN_API,
		url: `${CustomerPortalRequests.CONTROLLER_ROOT}/${CustomerPortalRequests.PRESENCE_CONFIRMATION_EP.replace(':token', token)}`,
		method: HttpMethodEnum.PUT,
	})

	static searchUserAppointments = (): RequestConfigTP<ListResponseDTO<CustomerPortalUserAppointmentResponseDTO>> => ({
		baseUrlType: NeritClinSystemApiEnum.NERITCLIN_API,
		url: `${CustomerPortalRequests.CONTROLLER_ROOT}/${CustomerPortalRequests.SEARCH_USER_APPOINTMENTS_EP}`,
		method: HttpMethodEnum.GET,
	})

	static cancelUserAppointment = (dto: SessionCodesRequestDTO): RequestConfigTP<void> => ({
		baseUrlType: NeritClinSystemApiEnum.NERITCLIN_API,
		url: `${CustomerPortalRequests.CONTROLLER_ROOT}/${CustomerPortalRequests.CANCEL_USER_APPOINTMENT_EP}`,
		method: HttpMethodEnum.DELETE,
		params: dto,
	})

}
