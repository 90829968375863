import React from 'react'
import { RadioGroupCP } from 'submodules/nerit-framework-ui/common/components/form-fields/radio-group/RadioGroupCP'
import { DayPeriodEnum, DayPeriodLabelEnum } from 'submodules/nerit-framework-utils/utils/date/DayPeriodEnum'

interface ICPProps {
    value: DayPeriodEnum
    onChange: (period: DayPeriodEnum) => void
}

/**
 */
export function RadioGroupDayPeriodCP(props: ICPProps): JSX.Element {

    return (
        <RadioGroupCP
            type={'button'}
            onChange={props.onChange}
            selected={props.value}
            options={Object.values(DayPeriodEnum).map((dayPeriod) => (
                {
                    content: DayPeriodLabelEnum[dayPeriod],
                    value: dayPeriod
                }
            ))}
        />
    )
}
