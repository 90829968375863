import React, { useEffect, useState } from 'react'
import { CheckboxGroupCP } from 'submodules/nerit-framework-ui/common/components/form-fields/checkbox/CheckboxGroupCP'
import { IFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/types/IFormStateManager'
import { LoadingCP } from 'submodules/nerit-framework-ui/common/components/loading/basic/LoadingCP'
import { SchedulerFormModel } from 'modules/scheduler/components/wizard-scheduler/inner/SchedulerFormModel'
import { CustomerPortalSessionsAvailableSearchResponseDTO } from 'submodules/neritclin-sdk/services/customer-portal/customer-portal/dtos/response/CustomerPortalSessionsAvailableSearchResponseDTO'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'
import { CustomerPortalRequests } from 'submodules/neritclin-sdk/services/customer-portal/customer-portal/CustomerPortalRequests'
import { ListResponseDTO } from 'submodules/nerit-framework-utils/sdk-utils/dtos/response/ListResponseDTO'
import { SelectFranchiseFromLoggedUserCP } from 'modules/common/components/select-franchise/SelectFranchiseFromLoggedUserCP'
import { CustomerPortalSearchScheduleAvailableSlotsRequestDTO } from 'submodules/neritclin-sdk/services/customer-portal/customer-portal/dtos/request/CustomerPortalSearchScheduleAvailableSlotsRequestDTO'
import { SCHEDULER_SCREEN_SESSIONS_PARAM } from 'modules/scheduler/screens/screen-scheduler/ScreenScheduler'
import { useLocation } from 'react-router-dom'

interface ICPProps {
    loggedCustomerCode: number
    formStateManager: IFormStateManager<SchedulerFormModel>
}

/**
 */
export function WizardSchedulerAttendanceStepICP(props: ICPProps): JSX.Element {

    const routeLocation = useLocation()

    const [selectedSessionCodes, setSelectedSessionCodes] = useState<number[]>()

    const [customerSessions, setCustomerSessions] = useState<CustomerPortalSessionsAvailableSearchResponseDTO[]>()
    const getCustomerSessions = useRequest<ListResponseDTO<CustomerPortalSessionsAvailableSearchResponseDTO>>()
    useEffect(onClientsRequestChange, [getCustomerSessions.isAwaiting])

    useEffect(init, [props.formStateManager.getFieldValue('franchiseCode')])

    /**
     */
    function init(): void {

        const franchiseCode = props.formStateManager.getFieldValue('franchiseCode')
        if (!franchiseCode)
            return

        getSessionsAvailable(franchiseCode)
    }

    /**
     */
    function getSessionsAvailable(franchiseCode: number): void {

        const dto: CustomerPortalSearchScheduleAvailableSlotsRequestDTO = {
            franchiseCode: franchiseCode
        }
        getCustomerSessions.runRequest(CustomerPortalRequests.searchSessionsAvailable(dto))
    }

    /**
     */
    function onClientsRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(getCustomerSessions, 'Erro ao buscar lista de clientes'))
            return

        setCustomerSessions(getCustomerSessions.responseData!.list)

        // Verifica se foi enviado sessoes pelo queryParam, e ja mostra selecionado
        const sessionCodes: string[] = new URLSearchParams(routeLocation.search).getAll(SCHEDULER_SCREEN_SESSIONS_PARAM)
        onSelect(sessionCodes.map((sessionCode) => +sessionCode))
    }

    /**
     */
    function onSelect(sessionCodes: number[]): void {

        setSelectedSessionCodes(sessionCodes)

        // Coloca no formModel o objeto, procurando para codigo de sessao no array original
        props.formStateManager.changeFieldValue('sessions', customerSessions?.filter((customerSession) => sessionCodes.includes(customerSession.sessionCode)))
    }

    return (
        <>
            <LoadingCP text={'Carregando Atendimentos'} show={getCustomerSessions.isAwaiting} size={18}/>

            {
                !!props.formStateManager.getFieldValue('franchiseCode')
                    ?
                    <CheckboxGroupCP
                        value={selectedSessionCodes}
                        onChange={onSelect}
                        mode={'vertical'}
                        options={
                            customerSessions?.map((customerSession) => ({
                                label: `${customerSession.treatment.name} (${customerSession.sessionNumber}/${customerSession.totalSessions})`,
                                value: customerSession.sessionCode,
                            })) ?? []
                        }
                    />
                    :
                    <SelectFranchiseFromLoggedUserCP
                        formStateManager={props.formStateManager}
                        fieldName={'franchiseCode'}
                    />
            }
        </>
    )
}
