import { IReduxState } from 'app/redux/interfaces/IReduxState'
import { ReduxUtils } from 'submodules/nerit-framework-ui/common/redux/ReduxUtils'
import { ISchemaResponseDTO } from 'app/services/dtos/response/ISchemaResponseDTO'
import { CustomerAuthResponseDTO } from 'submodules/neritclin-sdk/services/customer-portal/auth/dtos/responses/CustomerAuthResponseDTO'

export const REDUCER_LOGGED_USER = 'REDUCER_LOGGED_USER'
export const REDUCER_UPDATE_DOMAIN = 'update_domain'

/**
 * Determina mapa de reducers para gestao de estado da aplicacao via redux.
 */
export const Reducers: IReduxState = {

    auth: ReduxUtils.getPayloadReplacingReducer<CustomerAuthResponseDTO>(REDUCER_LOGGED_USER, null),
    domainData: ReduxUtils.getPayloadReplacingReducer<ISchemaResponseDTO>(REDUCER_UPDATE_DOMAIN, null),
}
