import { IsOptional } from 'class-validator/decorator/decorators'
import { IsRequired } from 'submodules/nerit-framework-utils/validators'
import { FormModel } from 'submodules/nerit-framework-ui/common/form-state-manager/types/FormModel'
import { CustomerPortalSessionsAvailableSearchResponseDTO } from 'submodules/neritclin-sdk/services/customer-portal/customer-portal/dtos/response/CustomerPortalSessionsAvailableSearchResponseDTO'
import { DateRangeStringResponseDTO } from 'submodules/nerit-framework-utils/sdk-utils/dtos/response/DateRangeStringResponseDTO'

export class SchedulerFormModel extends FormModel {

    @IsRequired()
    franchiseCode: number

    @IsRequired()
    sessions: CustomerPortalSessionsAvailableSearchResponseDTO[]

    @IsRequired()
    dateInterval: DateRangeStringResponseDTO

    @IsRequired()
    userCodeProfessional: number

    @IsOptional()
    comment?: string

    constructor(initialData?: any) {
        super(initialData)
        Object.assign(this, initialData)
    }
}
