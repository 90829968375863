import { ScreenError404 } from 'submodules/nerit-framework-ui/common/screens/ScreenError404'
import React from 'react'
import { RouterSwitchCP } from 'submodules/nerit-framework-ui/common/router/components/router-switch/RouterSwitchCP'
import { ScreenLogin } from 'app/screens/screen-login/ScreenLogin'
import { PrivateUserRouter } from 'app/routers/private/PrivateUserRouter'
import { ScreenPresenceConfirmation } from 'modules/scheduler/screens/screen-presence-confirmation/ScreenPresenceConfirmation'

/**
 * ROTAS
 */
PublicRouter.ROOT = 'public'

PublicRouter.LOGIN = `${PublicRouter.ROOT}/login`
PublicRouter.PRESENCE_CONFIRMATION = `${PublicRouter.ROOT}/confirmacao-presenca/:token`

/**
 * Define rotas que podem ser acessadas sem realizar login.
 */
export function PublicRouter(): JSX.Element {

    return (
        <RouterSwitchCP
            routes={[
                { path: PublicRouter.LOGIN, component: ScreenLogin, exact: true },
                { path: PublicRouter.PRESENCE_CONFIRMATION, component: ScreenPresenceConfirmation, exact: true },

                { component: () => <ScreenError404 redirectTo={PrivateUserRouter.HOME}/> },
            ]}
        />
    )
}
