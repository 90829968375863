import { OrUndefinedTP } from 'submodules/nerit-framework-utils/utils/types/OrUndefinedTP'
import { ReduxHelper } from 'app/redux/helpers/ReduxHelper'
import { ISchemaResponseDTO } from 'app/services/dtos/response/ISchemaResponseDTO'
import { CustomerAuthResponseDTO } from 'submodules/neritclin-sdk/services/customer-portal/auth/dtos/responses/CustomerAuthResponseDTO'
import { CustomerAuthDataResponseDTO } from 'submodules/neritclin-sdk/services/customer-portal/auth/dtos/responses/CustomerAuthDataResponseDTO'

/**
 */
export const AppStateUtils = {

    getLoggedUser(): OrUndefinedTP<CustomerAuthDataResponseDTO> {
        return AppStateUtils.getAuthData()?.customer
    },

    getAuthData(): OrUndefinedTP<CustomerAuthResponseDTO> {
        return (ReduxHelper.getInstance().store.getState().auth as CustomerAuthResponseDTO)
    },

    getDomainData(): OrUndefinedTP<ISchemaResponseDTO> {
        return ReduxHelper.getInstance().store.getState().domainData
    },

}
