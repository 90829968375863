import * as React from 'react'
import styled from 'styled-components'
import { ScreenResultCP } from 'submodules/nerit-framework-ui/common/components/screen/screen-result/ScreenResultCP'
import { FlexCP } from 'submodules/nerit-framework-ui/common/components/flex/FlexCP'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import { NeritFrameworkRoutingHelper } from 'submodules/nerit-framework-ui/common/router/utils/NeritFrameworkRoutingHelper'

interface ICPProps {
    redirectTo: string
}

/**
 * Pagina 404 - Pagina nao encontrada
 */
export function ScreenError404(props: ICPProps) {

    return (
        <WrapperSCP>
            <ScreenResultCP
                status={'404'}
                title={'Ops! Página não encontrada'}
                bottomContent={
                    <FlexCP justifyContent={'center'}>
                        <ButtonCP
                            type={'primary'}
                            onClick={() => NeritFrameworkRoutingHelper.historyPush(props.redirectTo)}
                            icon={'left-circle'}
                        >
                            Ir para página inicial
                        </ButtonCP>
                    </FlexCP>
                }
            />
        </WrapperSCP>
    )

}


const WrapperSCP = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
`
