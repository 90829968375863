import React from 'react'
import styled from 'styled-components'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import { LayoutPortalPagesCP } from 'app/components/layout-portal-pages/LayoutPortalPagesCP'
import { NeritFrameworkRoutingHelper } from 'submodules/nerit-framework-ui/common/router/utils/NeritFrameworkRoutingHelper'
import { PrivateUserRouter } from 'app/routers/private/PrivateUserRouter'

/**
 */
export function ScreenHome(): JSX.Element {

    return (
        <LayoutPortalPagesCP
            header={{
                title: 'Home',
            }}
            content={
                <WrapperButtonsSCP>
                    <ButtonCP
                        icon={'plus'}
                        type={'primary'}
                        color={'FF3684'}
                        size={'large'}
                        onClick={() => NeritFrameworkRoutingHelper.historyPush(PrivateUserRouter.SCHEDULER)}
                    >
                        Agendar atendimento
                    </ButtonCP>

                    <ButtonCP
                        icon={'calendar'}
                        size={'large'}
                        onClick={() => NeritFrameworkRoutingHelper.historyPush(PrivateUserRouter.APPOINTMENTS)}
                    >
                        Ver meus agendamentos
                    </ButtonCP>
                </WrapperButtonsSCP>
            }
        />
    )
}

const WrapperButtonsSCP = styled.div`
  padding: 20px 0;

  button {
    padding: 50px 0;
    margin-bottom: 10px;
    width: 100%;
    justify-content: center;
  }
`
