export enum MimeTypeEnum {

    PDF = 'application/pdf',
    ZIP = 'application/zip',
    JSON = 'application/json',
    TEXT = 'text/plain',
    ICO = 'image/vnd.microsoft.icon',
    PNG = 'image/png',

    XLSX = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    XLS = 'application/vnd.ms-excel',

    CSV_STANDARD = 'text/csv',
    CSV_ALTERNATIVE_1 = 'application/csv',
    CSV_ALTERNATIVE_2 = 'text/x-csv',
    CSV_ALTERNATIVE_3 = 'text/x-comma-separated-values',
    CSV_ALTERNATIVE_4 = 'text/comma-separated-values',
    CSV_MICROSOFT = 'application/vnd.ms-excel',
}
