import React from 'react'
import { Row } from 'antd'

interface IRowCPProps {
    children: React.ReactNode
    flex?: boolean
    justify?: 'space-around' | 'space-between' | 'center' | 'end' | 'start'
    align?: 'stretch' | 'bottom' | 'top' | 'middle'
    spaceBetweenCols?: number
}

/**
 * Componente de LINHA para alinhamento de GRID
 */
export const RowCP = (props: IRowCPProps): JSX.Element => {
    return (
        <Row
            gutter={props.spaceBetweenCols ?? 16}
            type={props.flex ? 'flex' : undefined}
            justify={props.justify}
            align={props.align}
        >
            {props.children}
        </Row>
    )
}
