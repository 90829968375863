import styled from 'submodules/nerit-framework-ui/theme/_old/styledWithTheme'
import * as React from 'react'
import { TextCP } from 'submodules/nerit-framework-ui/common/components/text/TextCP'
import { NeritFrameworkProjectConfig } from 'config/NeritFrameworkProjectConfig'
import { FontSizeTP } from 'submodules/nerit-framework-ui/theme/_old/types/ThemeTypes'

interface ICPProps {
    size?: FontSizeTP
}

/**
 */
export function UpdateAppVersionCP(props: ICPProps): JSX.Element {

    return (
        <ForceUpdateSCP>
            <TextCP
                size={props.size}
                icon={'reload'}
                text={`Forçar atualização (${NeritFrameworkProjectConfig.APP_VERSION})`}
                fontStyle={'italic'}
                onClick={() => window.location.reload(true)}
            />
        </ForceUpdateSCP>
    )
}

const ForceUpdateSCP = styled.div`
  border-top: solid 1px #eeeeee;
  margin-left: -16px;
  margin-right: -16px;
  padding: 10px 10px 0 10px;
`
