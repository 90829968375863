import React from 'react'
import { ProfileMenuItemCP } from 'submodules/nerit-framework-ui/common/components/menu/menu-item-profile/ProfileMenuItemCP'
import { HeaderCP } from 'submodules/nerit-framework-ui/common/components/screen/layout/header/HeaderCP'
import { PrivateUserRouter } from 'app/routers/private/PrivateUserRouter'
import { NeritFrameworkRoutingHelper } from 'submodules/nerit-framework-ui/common/router/utils/NeritFrameworkRoutingHelper'
import { AuthActions } from 'app/utils/AuthActions'
import { ThemeProject } from 'config/theme/project/ThemeProject'
import { AppStateUtils } from 'app/redux/AppStateUtils'
import { StringUtils } from 'submodules/nerit-framework-utils/utils/StringUtils'

interface ICPProps {
    title: string
}

/**
 */
export function HeaderPrivatePagesCP(props: ICPProps): JSX.Element {

    return (
        <HeaderCP
            title={props.title}
            titleUnderlineColor={ThemeProject.customerPortalTitleBarColor}
            bgColor={ThemeProject.customerPortalHeaderBackgroundColor}
            color={ThemeProject.customerPortalHeaderFontColor}
        >
            {
                !!AppStateUtils.getLoggedUser() &&
                <ProfileMenuItemCP
                    isTopMenu={true}
                    optionTitle={StringUtils.getFirstName(AppStateUtils.getLoggedUser()!.personName)}
                    profileOptions={[
                        {
                            text: 'Home',
                            action: () => NeritFrameworkRoutingHelper.historyPush(PrivateUserRouter.HOME)
                        },
                        {
                            text: 'Novo agendamento',
                            action: () => NeritFrameworkRoutingHelper.historyPush(PrivateUserRouter.SCHEDULER)
                        },
                        {
                            text: 'Meus agendamentos',
                            action: () => NeritFrameworkRoutingHelper.historyPush(PrivateUserRouter.APPOINTMENTS)
                        },
                        {
                            text: 'Sair',
                            action: () => AuthActions.logout()
                        },
                    ]}
                />
            }
        </HeaderCP>
    )
}
