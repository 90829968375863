import { IThemeProject } from 'submodules/neritclin-components-ui/theme/project/IThemeProject'

export const ThemeProjectNeritClin: IThemeProject = {

    collapsedMenuButtonColor: '#6d1c99',

    primaryButtonBackgroundColor: '#6d1c99',
    primaryButtonFontColor: '#FFF',

    primaryMenuColor: '#fff',
    primaryMenuItemColor: '#6d1c99',

    secondaryMenuColor: '#e2d6e8',
    secondaryBorderColor: '#e2d6e8',
    secondaryMenuItemColor: '#736c77',
    secondaryMenuTitleColor: '#6d1c99',

    headerBackgroundColor: '#6d1c99',
    headerFontColor: '#fff',
    titleBarColor: '#f59873',

    customerPortalHeaderBackgroundColor: '#6d1c99',
    customerPortalHeaderFontColor: '#fff',
    customerPortalTitleBarColor: '#f59873',

    checkedBackgroundColor: '#f59873',
    checkedColor: '#000',

    loginCoverColor: '#f3b900',

    widgetFooterColor: '#fff',

    serviceWorkerBarColor: '#f59873',
    serviceWorkerFontColor: '#000',

    logoMenuUrl: `${process.env.PUBLIC_URL}/logos/white-labels/neritclin/menu-icon.png`,
}
