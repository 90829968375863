import * as React from 'react'
import styled from 'styled-components'
import { BasicStyleWrapperCP, BasicStyleWrapperCPProps } from 'submodules/nerit-framework-ui/common/components/basic-wrappers/BasicStyleWrapperCP'

interface IScpProps {
    alignItems?: 'center' | 'flex-end' | 'space-between' | 'flex-start'
    justifyContent?: 'center' | 'flex-end' | 'space-between' | 'flex-start'
    flexDirection?: 'column'
    flexWrap?: 'wrap'
}

interface IFlexCPProps extends IScpProps, BasicStyleWrapperCPProps {
    children: React.ReactNode
    className?: string
}

/**
 * Para fazer um displex flex
 */
export function FlexCP(props: IFlexCPProps): JSX.Element {

    return (
        <BasicStyleWrapperCP
            margin={props.margin}
            onClick={props.onClick}
            className={`${props.className}-parent`}
        >

            <FilterWrapperSCP
                className={props.className}
                alignItems={props.alignItems}
                justifyContent={props.justifyContent}
                flexWrap={props.flexWrap}
                flexDirection={props.flexDirection}
            >
                {props.children}
            </FilterWrapperSCP>

        </BasicStyleWrapperCP>
    )
}

const FilterWrapperSCP = styled.div<IScpProps>`
  display: flex;
  align-items: ${props => props.alignItems ?? ''};
  justify-content: ${props => props.justifyContent ?? ''};
  flex-wrap: ${props => props.flexWrap ?? ''};
  flex-direction: ${props => props.flexDirection};
`
