import React, { useState } from 'react'
import styled from 'submodules/nerit-framework-ui/theme/_old/styledWithTheme'
import { useFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/UseFormStateManager'
import { LoginFormModel } from 'app/components/form-login/form-model/LoginFormModel'
import { FormLoginDataICP } from 'app/components/form-login/inner/FormLoginDataICP'
import { FormLoginSendTokenICP } from 'app/components/form-login/inner/FormLoginSendTokenICP'

/**
 */
export function FormLoginCP(): JSX.Element {

    const [view, setView] = useState<'sendToken' | 'login'>('login')

    const [formModel] = useState<LoginFormModel>(new LoginFormModel())
    const formStateManager = useFormStateManager(formModel)

    return (
        <FormContainerSCP>
            <FormTitleSCP>PORTAL DE AGENDAMENTO</FormTitleSCP>
            {
                view === 'login' &&
                <>
                    <FormLoginDataICP
                        formStateManager={formStateManager}
                        onRequestToken={() => setView('sendToken')}
                    />
                </>
            }
            {
                view === 'sendToken' &&
                <>
                    <FormLoginSendTokenICP
                        formStateManager={formStateManager}
                        onTokenRequested={() => setView('login')}
                        onGoBack={() => setView('login')}
                    />
                </>
            }
        </FormContainerSCP>
    )
}

const FormContainerSCP = styled.div`
  width: 100%;
  max-width: 365px;
  height: 100%;
  margin-top: 30px;
`

const FormTitleSCP = styled.span`
  margin-bottom: 20px;
  font-weight: bold;
  display: flex;
  justify-content: center;
`
