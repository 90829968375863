import React, { useEffect } from 'react'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import { InputCP } from 'submodules/nerit-framework-ui/common/components/form-fields/input/InputCP'
import { IconICP } from 'submodules/nerit-framework-ui/common/components/icon/inner/IconICP'
import { LoginFormModel } from 'app/components/form-login/form-model/LoginFormModel'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'
import { CustomerAuthRequests } from 'submodules/neritclin-sdk/services/customer-portal/auth/CustomerAuthRequests'
import { IFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/types/IFormStateManager'
import { NotificationHelper } from 'submodules/nerit-framework-ui/common/components/notification/inner/NotificationHelper'
import { SendValidationTokenRequestDTO } from 'submodules/neritclin-sdk/services/customer-portal/auth/dtos/requests/SendValidationTokenRequestDTO'
import { FlexCP } from 'submodules/nerit-framework-ui/common/components/flex/FlexCP'
import { InputMaskTypeEnum } from 'submodules/nerit-framework-ui/common/form-state-manager/enums/InputMaskTypeEnum'

interface ICPProps {
    formStateManager: IFormStateManager<LoginFormModel>
    onTokenRequested: () => void
    onGoBack: () => void
}

/**
 * Formulario de login de paciente.
 */
export function FormLoginSendTokenICP(props: ICPProps): JSX.Element {

    const sendTokenRequest = useRequest<void>('none')
    useEffect(onSendTokenRequestChange, [sendTokenRequest.isAwaiting])

    /**
     */
    function sendToken(): void {

        const formValues = props.formStateManager.getFormValues()
        if (!formValues?.phone) {
            NotificationHelper.error('Ops', 'Informe o seu telefone para enviarmos o token')
            return
        }

        const dto: SendValidationTokenRequestDTO = {
            phone: formValues.phone,
        }
        sendTokenRequest.runRequest(CustomerAuthRequests.sendToken(dto))
    }

    /**
     */
    function onSendTokenRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(sendTokenRequest, 'Ocorreu algum erro ao enviar o token', 'Token enviado com sucesso, só informar no campo abaixo', true))
            return

        props.onTokenRequested()
    }

    return (
        <>
            <InputCP
                label={'Telefone'}
                fieldName={'phone'}
                formStateManager={props.formStateManager}
                mask={InputMaskTypeEnum.PHONE}
                required={true}
                icon={<IconICP iconName={'lock'}/>}
            />

            <FlexCP
                justifyContent={'space-between'}
                margin={{ top: 20 }}
            >
                <ButtonCP
                    onClick={props.onGoBack}
                >
                    Voltar
                </ButtonCP>

                <ButtonCP
                    type={'primary'}
                    onClick={sendToken}
                    loading={sendTokenRequest.isAwaiting}
                >
                    Enviar Código de Verificação
                </ButtonCP>
            </FlexCP>
        </>
    )
}
