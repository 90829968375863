import { IThemeProject } from 'submodules/neritclin-components-ui/theme/project/IThemeProject'

export const ThemeProjectLisoLaser: IThemeProject = {

    collapsedMenuButtonColor: '#6d1c99',

    primaryButtonBackgroundColor: '#ff3684',
    primaryButtonFontColor: '#fff',

    primaryMenuColor: '#ff6643',
    primaryMenuItemColor: '#fff',

    secondaryMenuColor: '#fff',
    secondaryBorderColor: '#fff',
    secondaryMenuItemColor: '#6e1d61',
    secondaryMenuTitleColor: '#6e1d61',

    headerBackgroundColor: '#fff',
    headerFontColor: '#ff6643',
    titleBarColor: '#ff6643',

    customerPortalHeaderBackgroundColor: '#ff6643',
    customerPortalHeaderFontColor: '#E4E814',
    customerPortalTitleBarColor: '#E4E814',

    checkedBackgroundColor: '#f59873',
    checkedColor: '#000',

    loginCoverColor: '#ff6643',

    widgetFooterColor: '#fff',

    serviceWorkerBarColor: '#f59873',
    serviceWorkerFontColor: '#000',

    logoMenuUrl: `${process.env.PUBLIC_URL}/logos/white-labels/lisolaser/menu-icon.png`,
}
