import { ReduxHelper } from 'app/redux/helpers/ReduxHelper'
import { ThemeProvider } from 'submodules/nerit-framework-ui/theme/_old/styledWithTheme'
import { ThemeAnt } from 'config/theme/ant/ThemeAnt'
import React from 'react'
import { Provider as ReduxProvider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { PersistGate } from 'redux-persist/integration/react'
import { LoadingOverlayCP } from 'submodules/nerit-framework-ui/common/components/loading/overlay/LoadingOverlayCP'
import { ConfigProvider as AntDProvider } from 'antd'
import { AppRouter } from 'app/routers/AppRouter'
import { EmptyCP } from 'submodules/nerit-framework-ui/common/components/empty/EmptyCP'

/**
 * Componente inicial do sistema.
 */
export function App(): JSX.Element {

    return (
        <ReduxProvider store={ReduxHelper.getInstance().store}>
            <PersistGate loading={<LoadingOverlayCP show={true}/>} persistor={ReduxHelper.getInstance().persistor as any}>
                <AntDProvider renderEmpty={() => <EmptyCP/>}>
                    <ThemeProvider theme={ThemeAnt as any}>
                        <BrowserRouter>

                            <AppRouter/>

                        </BrowserRouter>
                    </ThemeProvider>
                </AntDProvider>
            </PersistGate>
        </ReduxProvider>
    )
}
