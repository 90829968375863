import React from 'react'
import 'react-photo-view/dist/react-photo-view.css'
import { LayoutPortalPagesCP } from 'app/components/layout-portal-pages/LayoutPortalPagesCP'
import { CalendarCP } from 'common/components/calendar/CalendarCP'
import { NotificationHelper } from 'submodules/nerit-framework-ui/common/components/notification/inner/NotificationHelper'
import moment from 'moment'

/**
 * Pagina para testar componentes de forma rapida
 *
 * Chamar na ROTA: /dev-test
 */
export function ScreenDevTest(): JSX.Element {

    function doNothing(): void {
        NotificationHelper.info('Do Nothing')
    }

    return (
        <LayoutPortalPagesCP
            header={{
                title: 'DEV TEST',
            }}
            content={
                <>

                    <CalendarCP
                        onChangeSelectedDate={doNothing}
                        minDate={new Date()}
                        onChangeNavigation={(date) => console.log(date)}
                        disabledDates={[
                            moment().add(1, 'day').toDate(),
                            moment().add(2, 'day').toDate(),
                            moment().add(3, 'day').toDate(),
                            moment().add(4, 'day').toDate(),
                            moment().add(7, 'day').toDate(),
                            moment().add(10, 'day').toDate(),
                            moment().add(-1, 'day').toDate(),
                        ]}
                    />

                </>
            }
        />
    )
}
