import React, { useEffect } from 'react'
import { ScreenResultCP } from 'submodules/nerit-framework-ui/common/components/screen/screen-result/ScreenResultCP'
import { DescriptionCustomerAttendancesConfirmCP } from 'modules/scheduler/components/description-customer-attendances-confirm/DescriptionCustomerAttendancesConfirmCP'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import { FlexCP } from 'submodules/nerit-framework-ui/common/components/flex/FlexCP'
import { ButtonCancelAppointmentCP } from 'modules/scheduler/components/button-cancel-appointment/ButtonCancelAppointmentCP'
import { NeritFrameworkRoutingHelper } from 'submodules/nerit-framework-ui/common/router/utils/NeritFrameworkRoutingHelper'
import { PrivateUserRouter } from 'app/routers/private/PrivateUserRouter'
import { CustomerPortalUserAppointmentResponseDTO } from 'submodules/neritclin-sdk/services/customer-portal/customer-portal/dtos/response/CustomerPortalUserAppointmentResponseDTO'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { CustomerPortalRequests } from 'submodules/neritclin-sdk/services/customer-portal/customer-portal/CustomerPortalRequests'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'
import { NotificationHelper } from 'submodules/nerit-framework-ui/common/components/notification/inner/NotificationHelper'

interface ICPProps {
    token: string
    userAppointment: CustomerPortalUserAppointmentResponseDTO
    onConfirm: () => void
}

/**
 */
export function ScreenContentConfirmPresence(props: ICPProps): JSX.Element {

    const confirmPresenceRequest = useRequest<void>('none')
    useEffect(onConfirmPresenceRequestChange, [confirmPresenceRequest.isAwaiting])

    /**
     */
    function onConfirmPresenceRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(confirmPresenceRequest, NotificationHelper.DEFAULT_ERROR_SAVE_MESSAGE, NotificationHelper.DEFAULT_SUCCESS_SAVE_MESSAGE))
            return

        props.onConfirm()
    }

    return (
        <ScreenResultCP
            status={'info'}
            title={'Confirmação de Presença'}
            message={'Você confirma presença do(s) antendimento(s) no dia/horário abaixo?'}
            bottomContent={
                <>
                    <DescriptionCustomerAttendancesConfirmCP
                        dateInterval={props.userAppointment.dateRange}
                        treatmentsNames={props.userAppointment.sessions.map((session) => session.treatment.name)}
                    />

                    <FlexCP justifyContent={'space-between'} margin={{top: 30}}>
                        <ButtonCancelAppointmentCP
                            sessionCodes={props.userAppointment.sessions.map((sessionData) => sessionData.sessionCode)}
                            mode={'reschedule'}
                            onCancel={() => NeritFrameworkRoutingHelper.historyPush(PrivateUserRouter.SCHEDULER)}
                        />
                        <ButtonCP
                            type={'primary'}
                            onClick={() => confirmPresenceRequest.runRequest(CustomerPortalRequests.confirmPresence(props.token))}
                            loading={confirmPresenceRequest.isAwaiting}
                        >
                            Confirmar
                        </ButtonCP>
                    </FlexCP>
                </>
            }
        />
    )
}
