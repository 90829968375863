import React from 'react'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import { FlexCP } from 'submodules/nerit-framework-ui/common/components/flex/FlexCP'
import { IFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/types/IFormStateManager'
import { SchedulerFormModel } from 'modules/scheduler/components/wizard-scheduler/inner/SchedulerFormModel'
import { ScreenResultCP } from 'submodules/nerit-framework-ui/common/components/screen/screen-result/ScreenResultCP'
import { DescriptionCustomerAttendancesConfirmCP } from 'modules/scheduler/components/description-customer-attendances-confirm/DescriptionCustomerAttendancesConfirmCP'
import { NeritFrameworkRoutingHelper } from 'submodules/nerit-framework-ui/common/router/utils/NeritFrameworkRoutingHelper'
import { PrivateUserRouter } from 'app/routers/private/PrivateUserRouter'
import { CustomerPortalSessionsAvailableSearchResponseDTO } from 'submodules/neritclin-sdk/services/customer-portal/customer-portal/dtos/response/CustomerPortalSessionsAvailableSearchResponseDTO'

interface ICPProps {
    formStateManager: IFormStateManager<SchedulerFormModel>
}

/**
 */
export function ResultSchedulerCP(props: ICPProps): JSX.Element {

    return (
        <ScreenResultCP
            status={'success'}
            title={'Agendamento confirmado!'}
            message={'Obrigado, nos vemos em breve.'}
            bottomContent={
                <>
                    <DescriptionCustomerAttendancesConfirmCP
                        dateInterval={props.formStateManager.getFieldValue('dateInterval')}
                        treatmentsNames={props.formStateManager.getFieldValue('sessions').map((session: CustomerPortalSessionsAvailableSearchResponseDTO) => session.treatment.name)}
                    />

                    <FlexCP justifyContent={'center'} margin={{ top: 30 }}>
                        <ButtonCP
                            type={'primary'}
                            onClick={() => NeritFrameworkRoutingHelper.historyPush(PrivateUserRouter.APPOINTMENTS)}
                        >
                            Ver meus agendamentos
                        </ButtonCP>
                    </FlexCP>
                </>
            }
        />
    )
}
